@font-face {
  font-family: Union;
  font-weight: regular;
  src: url("./fonts/Union-Regular.otf") format("opentype");
}

@font-face {
  font-family: Union;
  font-weight: bold;
  src: url("./fonts/Union-Bold.otf") format("opentype");
}

@keyframes color-anim {
  0% { color: rgb(240, 24, 24); }
  33% { color: rgb(255, 85, 0); }
  66% { color: rgb(255, 0, 200); }
  100% { color: rgb(240, 24, 24); }
}

@keyframes highlight-anim {
  0% { text-shadow: 0px 0px 10px rgb(252, 176, 176), 0px 0px 20px rgb(252, 176, 176); }
  33% { text-shadow: 0px 0px 10px rgb(255, 182, 146), 0px 0px 20px rgb(255, 182, 146); }
  66% { text-shadow: 0px 0px 10px rgb(249, 154, 228), 0px 0px 20px rgb(249, 154, 228); }
  100% { text-shadow: 0px 0px 10px rgb(252, 176, 176), 0px 0px 20px rgb(252, 176, 176); }
}

button {
  cursor: pointer;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.5s; /* Firefox < 16 */
   -ms-animation: fadein 0.5s; /* Internet Explorer */
    -o-animation: fadein 0.5s; /* Opera < 12.1 */
       animation: fadein 0.5s;
}

.lightbox img {
  max-width: 90%;
}

.Cakes {
  text-align: center;
  display: flex;
  align-items: center;

  font-family: Union;

  gap: 5vh;

  left: 50%;
  transform: translateX(-50%);
  position: relative;

  width: 70%;

  margin-top: 15vh;
  margin-bottom: 25vh;

  flex-direction: column;
  height: fit-content;
}

.emoji {
  font-size: 1.7em;
}

@-webkit-keyframes bounce {
	0%, 20%, 50%, 80%, 100% {-webkit-transform: translate(-50%, 20%);}	
	40% {-webkit-transform: translate(-50%,10%);}
	60% {-webkit-transform: translate(-50%,15%);}
}
 
@-moz-keyframes bounce {
	0%, 20%, 50%, 80%, 100% {-moz-transform: translate(-50%,20%);}
	40% {-moz-transform: translate(-50%,10%);}
	60% {-moz-transform: translate(-50%,15%);}
}
 
@-o-keyframes bounce {
	0%, 20%, 50%, 80%, 100% {-o-transform: translate(-50%,20%);}
	40% {-o-transform: translate(-50%,10%);}
	60% {-o-transform: translate(-50%,15%);}
}
@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {transform: translate(-50%,20%);}
	40% {transform: translate(-50%,10%);}
	60% {transform: translate(-50%,15%);}
}

.down-arrow {
  bottom: 0;
  left: 10%;
  top: 20%;
  font-size: 40px;
  width: 10%;
  position: absolute;
  transform: translate(-50%, 0%);
  animation: bounce 2s infinite;
  color: #60035b;
  text-shadow: 0px 0px 20px #ff0dbb99, 0px 0px 20px #ff0dbb99;
	-webkit-animation: bounce 2s infinite;
	-moz-animation: bounce 2s infinite;
	-o-animation: bounce 2s infinite;
}

.Golden {}

.Golden-back {
  color: #3a0348;

  text-shadow: 0px 0px 5px #ffffff, 0px 0px 5px #ffffff, 0px 0px 10px #ffffff;

  box-shadow: 0px 0px 40px #0ce6b7f5;

  border: 1px solid rgb(81, 81, 81);
  width: fit-content;
  backdrop-filter: blur(5px);
  background-color: rgba(255,255,255,0.2);
  border-radius: 5px;
  padding: 10px;
  border-radius: 10px;

  position: fixed;
  z-index: 2;
}

.Golden-mint {
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;
  height: fit-content;

  position: absolute;

  padding-bottom: 10vh;
}

.Golden-img {
  position: relative;

  filter: drop-shadow(0px 0px 40px rgba(255, 255, 255, 0.7));
}

.bg {
  z-index: -1;
  background-image: url("./images/bg.jpg") !important;
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0px;
  top: 0px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.logo-and-info {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;
}

.mint-ui {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  height: fit-content;
}

.mint-ui-2x2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2vw;
  grid-row-gap: 2vh;
  width: 40%;
  justify-items: center;
  align-content: center;
}  

.mint-and-pricing {
  display: flex;
  flex-direction: row;
  width: 70%;

  background-color: rgb(224 172 196 / 30%);
  border-radius: 20px;
  backdrop-filter: blur(3px);
  border: 1px solid #3f3f3f62;
}

.yeche-logo {
  position: relative;
  filter: drop-shadow(0px 0px 3px rgb(249, 109, 84));
  /*top: 50%;
  transform: translateY(-50%);*/
}

.golden-promo {
  position: relative;

  line-height: 2.5;

  font-size: 1.2em;

  margin: 20px;

  width: auto;

  padding: 3%;

  overflow: hidden;

  box-sizing: border-box;

  background-color: rgb(224 172 196 / 30%);

  border-radius: 20px;

  backdrop-filter: blur(3px);

  border: 1px solid #703d09ff;

  text-shadow: 0px 0px 5px #ffffff, 0px 0px 10px #ffffff, 0px 0px 10px #ffffff;

  box-shadow: 0px 0px 70px #dca014, 0px 0px 70px #dfa9137d;
  color: #141414;
}

.price-box {
  position: relative;

  text-align: right;

  line-height: 2.5;

  width: 60%;

  padding: 4%;

  overflow: hidden;

  box-sizing: border-box;

  /* background-color: rgb(224 172 196 / 30%);
  border-radius: 20px;
  backdrop-filter: blur(3px);
  border: 1px solid #3f3f3f62; */

  /*box-shadow: 0px 0px 10px #565656;*/
  color: #141414;
  text-shadow: 0px 0px 5px #ffffff, 0px 0px 10px #ffffff, 0px 0px 10px #ffffff;
}


.yeche-logo img {
  width: 90%;
}

.info-box {
  position: relative;
  /* top: 50%;
  transform: translateY(-50%); */

  line-height: 1.3;

  font-size: 20px;

  /* margin: 20px; */

  width: 100%;

  padding: 5%;

  overflow: hidden;

  box-sizing: border-box;

  text-align: left;

  background-color: rgb(224 172 196 / 30%);
  border-radius: 40px;

  backdrop-filter: blur(5px);

  /* desaturate background */


  border: 1px solid #3f3f3f62;

  /*box-shadow: 0px 0px 10px #565656;*/
  color: #000;
  text-shadow: 0px 0px 5px #ffffff, 0px 0px 20px #ffffff;
}

.info-box a {
  text-decoration: none;
  animation: color-anim 3s infinite, highlight-anim 5s infinite;
}

.connect-prompt {
  margin: 20px;

  width: auto;

  padding: 3%;

  overflow: hidden;

  box-sizing: border-box;

  background-color: rgb(224 172 196 / 30%);
  border-radius: 40px;

  backdrop-filter: blur(1px);

  box-shadow: 0px 0px 10px #565656;
  color: #141414;
  text-shadow: 0px 0px 5px #ffffff, 0px 0px 5px #ffffff, 0px 0px 5px #ffffff;
}


@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

.links {
  padding-top: 20px;

  font-size: 18px;

  text-align: center;

  display: grid;
  grid-template-columns: 1fr 1fr;

  text-shadow: 0px 0px 10px #ffffff, 0px 0px 20px #ffffff;
}

.links a {
  text-decoration: none;
  color: black;
  padding: 5px;
}

.yechecakes-logo {
  filter: drop-shadow(0px 0px 30px #00d0ff91);
}

.yechecakes-logo img {}

.img-grid-3x2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 2vw;
  grid-row-gap: 4vw;

  align-items: center;  
  justify-items: center;
  width: 100%;

  /* margin-top: 40px;
  margin-bottom: 40px; */
}

.img-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 2vw;
  grid-row-gap: 4vw;

  align-items: center;  
  justify-items: center;
  width: 100%;

  /* margin-top: 40px;
  margin-bottom: 40px; */
}

.cake-preview {
}

.cake-title {
  width: 70%;
  height: 80px;
  font-size: large;

  margin: auto;
  padding-top: 5px;
  text-shadow: 0px 0px 5px #ffffff, 0px 0px 10px #ffffff, 0px 0px 10px #ffffff; 

  color: #000;

  font-weight: bold;
}

.cake-title-3x2 {
  width: 70%;
  height: 80px;
  font-size: large;

  margin: auto;
  padding-top: 5px;
  text-shadow: 0px 0px 5px #ffffff, 0px 0px 10px #ffffff, 0px 0px 10px #ffffff; 

  color: #000;

  font-weight: bold;
}


.cake-img {
  width: 100%;
  height: auto;
  filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.7));

  cursor: -moz-zoom-in; 
  cursor: -webkit-zoom-in; 
  cursor: zoom-in;
}

.cake-img-lightbox {
  width: 100%;
  height: auto;
  filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.7));
}

.column-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Golden-title {
  width: 100%;
  font-size: 1.3em;

  line-height: 1.5;

  text-align: center;

  margin: auto;
  padding-top: 5px;
  text-shadow: 0px 0px 10px #ffffff, 0px 0px 10px #ffffff, 0px 0px 10px #ffffff; 
}

.mint-button-connected-2x2 {
  color: #ffffff;

  font-size: 1.4em;

  box-shadow: 0px 0px 20px #0ce6b7f5, 0px 0px 30px #0ce6b7f5;

  border: 1px solid rgb(0, 0, 0);
  width: fit-content;
  backdrop-filter: blur(5px);
  background-color: rgba(122, 188, 255, 0.5);
  border-radius: 5px;
  padding: 10px;
  border-radius: 10px;
}

.mint-button-disconnected-2x2 {
  color: #3a0348;

  font-size: 1.4em;

  box-shadow: 0px 0px 40px #cfcfcff5;

  border: 1px solid rgb(81, 81, 81);
  width: fit-content;
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 5px;
  padding: 10px;
  border-radius: 10px;
}

.mint-button-connected {
  color: #ffffff;

  font-size: 1.4em;

  box-shadow: 0px 0px 20px #0ce6b7f5, 0px 0px 30px #0ce6b7f5;

  border: 1px solid rgb(0, 0, 0);
  width: fit-content;
  backdrop-filter: blur(5px);
  background-color: rgba(122, 188, 255, 0.5);
  border-radius: 5px;
  padding: 10px;
  border-radius: 10px;

  margin: 20px;
}

.mint-button-disconnected {
  color: #3a0348;

  font-size: 1.4em;

  box-shadow: 0px 0px 40px #cfcfcff5;

  border: 1px solid rgb(81, 81, 81);
  width: fit-content;
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 5px;
  padding: 10px;
  border-radius: 10px;

  margin: 20px;
}

/* mobile */
@media only screen and (max-width: 480px) {

  .Cakes {
    width: 90%;
  }

  .mint-button-connected-2x2 {
    font-size: 0.6em;
    padding: 8px;
  }

  .mint-button-disconnected-2x2 {
    font-size: 0.6em;
    padding: 8px;
  }

  .mint-button-connected {
    font-size: 0.8em;
    margin: 10px;
    padding: 8px;
  }

  .mint-button-disconnected {
    font-size: 0.8em;
    margin: 10px;
    padding: 8px;
  }

  .mint-ui {
    width: 100%;
  }

  .mint-ui-2x2 {
    width: 40%;
    padding: 15px;
  }

  .mint-and-pricing {
    width: 100%;
  }

  .price-box {
    font-size: 0.6em;
    line-height: 2;
    width: 60%;
    padding: 15px;
  }

  .golden-promo {
    width: 100%;
  }

  .Golden-mint {
    left: 50%;  
    top: 60%;
    transform: translate(-50%, -60%);  
  }

  .Golden-title {
    font-size: 0.8em;
  }

  .Golden-img {
    width: 60vw;
  }

  .connect-btn {
    font-size: 12px;
  }

  .Golden-back {
    font-size: 12px;

    top: 18vh;
    right: 3vw;  
  }

  .column-text {
    flex: 1 1 100%;
  }
  
  .column-logo {
    flex: 1 1 100%;
  }  

  .logo-and-info {
    width: 100%;
  }

  .address-pill {
    font-size: 12px;
  }

  .marquee {
    font-size: 25px;
  }

  /* .mint-ui {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2vw;
    grid-row-gap: 4vh;
    align-items: center;
  }   */

  .cake-title-3x2 {
    font-size: 0.3em;
  }

  .info-box {
    font-size: 16px;
    padding: 6%;
  }

  .links {
    font-size: 12px;
  }

  .img-grid {
    grid-template-columns: 1fr;
    grid-row-gap: 8vh;
  }

  .img-grid-3x2 {
  }

  .yechecakes-logo img {
    width: 95%;
  }  
}

/* desktop */
@media only screen and (min-width: 480px) {
  .mint-ui {}

  .lightbox img {
    max-width: 50%;
  }

  .price-box {
    font-size: 1.2em;
  }

  .Golden-img {
    width: 70vw;
  }

  .Golden-mint {
    left: 50%;  
    top: 25%;
    transform: translate(-50%, 0%);  
  }

  .column-text {
    flex: 1 1 60%;
  }
  
  .column-logo {
    flex: 1 1 40%;
    gap: 100px;
  }  

  .logo-and-info {
    width: 80%;
  }

  .connect-btn {
    font-size: 20px;
  }

  .Golden-back {
    font-size: 20px;

    top: 25vh;
    left: 3vw;  
  }

  .Golden-preview {}  

  .address-pill {
    font-size: 20px;
  }

  .yechecakes-logo img {
    width: 50%;
  }  
}

.giveaway-mint-button-inactive {
  color: #3a0348;

  border: 1px solid rgb(103, 103, 103);
  width: fit-content;
  backdrop-filter: blur(5px);

  font-size: 1.2em;

  box-shadow: 0px 0px 40px #cfcfcff5;

  background-color: rgba(255, 255, 255, 0.4);
 
  border-radius: 5px;
  padding: 10px;
  border-radius: 10px;

  margin: 20px;
}

.giveaway-mint-button-active {
  color: #ffffff;

  border: 1px solid rgb(0, 0, 0);
  width: fit-content;
  backdrop-filter: blur(5px);

  font-size: 1.2em;

  box-shadow: 0px 0px 20px #e60c79f5, 0px 0px 30px #e60c79f5;
 
  background-color: rgb(255 122 238 / 40%);
 
  border-radius: 5px;
  padding: 10px;
  border-radius: 10px;

  margin: 20px;
}

.connect-btn {
  color: black;

  font-family: Union;

  z-index: 2;
  border: 1px solid #3f3f3f;
  backdrop-filter: blur(5px);
  background-color: rgba(255,255,255,0.2);
  position: fixed;
  border-radius: 5px;
  left: 3vw;
  top: 18vh;
  padding: 10px;
  border-radius: 10px;

  text-shadow: 0px 0px 5px #ffffff, 0px 0px 10px #ffffff, 0px 0px 10px #ffffff;

  animation: glow 1s infinite alternate;
}

@keyframes glow {
  from {
    box-shadow: 0px 0px 50px #0000009d, 0px 0px 30px #0000009d;
    color: #0000009d;

  }
  to {
    box-shadow: 0px 0px 50px #0ce6b7f5, 0px 0px 30px #0ce6b7f5;
    color: #9207b5f5;
  }
}

.address-pill {
  color: black;
  z-index: 2;
  border: 1px solid #3f3f3f;
  backdrop-filter: blur(5px);
  background-color: rgba(255,255,255,0.2);
  box-shadow: 0px 0px 30px #55ffe871;
  position: fixed;
  border-radius: 5px;
  left: 3vw;
  top: 18vh;
  padding: 10px;
  border-radius: 10px;
}