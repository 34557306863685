
@font-face {
    font-family: Union;
    font-weight: regular;
    src: url("./fonts/Union-Regular.otf") format("opentype");
}
  
@font-face {
    font-family: Union;
    font-weight: bold;
    src: url("./fonts/Union-Bold.otf") format("opentype");
}

@font-face {
    font-family: Agrandir;
    font-weight: bold;
    src: url("./fonts/Agrandir-GrandHeavy.otf") format("opentype");
}

@font-face {
    font-family: 'Pantasia';
    src: url(./fonts/Pantasia-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Duke';
    src: local('Duke'), url(./fonts/DUKECONN.TTF) format('truetype');
}

@keyframes color-anim {
    0% { color: rgb(240, 24, 24); }
    33% { color: rgb(255, 85, 0); }
    66% { color: rgb(255, 0, 200); }
    100% { color: rgb(240, 24, 24); }
}

@keyframes highlight-anim-mission {
    0% { text-shadow: 0px 0px 10px rgb(252, 176, 176), 0px 0px 5px rgb(252, 176, 176), 0px 0px 5px rgb(252, 176, 176); }
    33% { text-shadow: 0px 0px 10px rgb(255, 182, 146), 0px 0px 5px rgb(255, 182, 146), 0px 0px 5px rgb(255, 182, 146); }
    66% { text-shadow: 0px 0px 10px rgb(249, 154, 228), 0px 0px 5px rgb(249, 154, 228), 0px 0px 5px rgb(249, 154, 228); }
    100% { text-shadow: 0px 0px 10px rgb(252, 176, 176), 0px 0px 5px rgb(252, 176, 176), 0px 0px 5px rgb(252, 176, 176); }
}

@keyframes oreo-color-anim {
    0% { color: rgb(0, 0, 0); }
    50% { color: rgb(255, 255, 255); }
    100% { color: rgb(0, 0, 0); }
}

@keyframes oreo-color-anim-bar {
    0% { background: rgb(0, 0, 0); }
    50% { background: rgb(255, 255, 255); }
    100% { background: rgb(0, 0, 0); }
}

/* make the inverse of the above */
@keyframes oreo-color-anim-bar-inv {
    0% { background: rgb(255, 255, 255); }
    50% { background: rgb(0, 0, 0); }
    100% { background: rgb(255, 255, 255); }
}

@keyframes oreo-highlight-anim {
    0% { text-shadow: 0px 0px 5px rgb(255, 255, 255), 0px 0px 5px rgb(255, 255, 255), 0px 0px 5px rgb(255, 255, 255); }
    50% { text-shadow: 0px 0px 5px rgb(0, 0, 0), 0px 0px 5px rgb(0, 0, 0), 0px 0px 5px rgb(0, 0, 0); }
    100% { text-shadow: 0px 0px 5px rgb(255, 255, 255), 0px 0px 5px rgb(255, 255, 255), 0px 0px 5px rgb(255, 255, 255); }
}

.homeContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0vh;
    gap: 10px;
    flex-direction: column;
}
  
.bg-splash {
    z-index: -1;
    background-image: url("./images/splash_bg.jpg") !important;
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0px;
    top: 0px;
    padding: 1px;
    background-size: cover;
    background-position: 50% 13%;
    background-repeat: no-repeat;
}

@keyframes ticker {
    0% {
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
    100% {
      transform: translate3d(-100%, 0, 0);
    }
}

.ticker-wrap {
    /* position: fixed; */
    bottom: 0;
    width: 100%;
    overflow: hidden;
    height: 2rem;
    /* background-color: rgba(0, 0, 0, 0.9);  */
    font-family: Union;
    color: white;
    box-sizing: content-box;
}

.ticker {
    display: inline-block;
    height: 2rem;
    line-height: 2rem;  
    white-space: nowrap;
    padding-right: 100%; 
    box-sizing: content-box;
    
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: ticker;
    animation-duration: 120s;
}

.ticker__item {
    display: inline-block;
    padding: 0 2rem;
    font-size: 2rem;
    color: white;   

    font-size: 15px;
    font-weight: bold;

    animation: color-anim 10s infinite, highlight-anim-mission 10s infinite;
}

.delayMessage {
    font-size: xx-large;
    font-weight: bolder;
    color: green;

    /* make floating on top of page in center */
    position: absolute;

    text-align:center;

    /* add white drop shadow */
    text-shadow: 0px 0px 10px #ffffff, 0px 0px 5px #ffffff, 0px 0px 10px #ffffff;
    
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
    z-index: 1000;

}
  

.enterGallery {
    max-width: 100%;
    display: flex;
    justify-content: center;

    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.5));

    /* padding: 15px; */

    /* border: 1px solid black;
    background:rgba(255, 255, 255, 0.5);

    text-decoration: none !important;
    color: black; */

    /* font-family: Pantasia; */
}

.enterGallery a {
    text-decoration: none !important;
    color: black;
    font-family: Agrandir;
    font-size: 24px;
    padding: 10px;
}

.splashContent {
    display: flex;
    gap: 20px;
    flex-direction: column;
}

.currentRecent {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 10px;
}

.currentRecentSection {
    width: 50%;
    padding-top: 50%;
    position: relative;
    /* background-color: #ffffff; */
}

.currentRecentSectionInner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    gap: 3%;
}

.nowShowingContainer {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.nowShowingImg {
    background-image: url('./images/obaa_banner.jpg');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;

    position: absolute;

    text-decoration: none !important;
    /* justify-content: center; */
}

.sectionHeaders {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}
  
.splashSectionHeader {
    /* font-size: 24px; */
    margin-bottom: 10px;
    width: 50%;
    font-family: Duke;
    text-align: center;
    font-weight: bold;
    text-shadow: 0px 0px 10px #ffffff, 0px 0px 5px #ffffff, 0px 0px 10px #ffffff;
    animation: oreo-color-anim 4s infinite, oreo-highlight-anim 4s infinite;
}
  
.nowShowingTitle {
    font-size: 24px;
    position: relative;
    top: 100%;
    left: 10px;
    transform: translate(0%, -100%);
    font-family: Duke;
    font-weight: bold;

    text-shadow: 0px 0px 10px #ffffff, 0px 0px 5px #ffffff, 0px 0px 10px #ffffff;
    color: black;
}

.recentMintItem {
    width: 100%;
    height: 48.5%;
}

.recentMintImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.archive {
    display: flex;
    flex-wrap: wrap;   

    /* grid-template-columns: repeat(auto-fit, minmax(30px, 1fr)); */

    padding-left: 10px;
    padding-right: 10px;

    padding-bottom: 100px; 
}

.archiveGrid {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 5px;
    justify-content: center;
}

.archiveItem {
    width: 25%;
    /* height: 200px; */
}

.archiveImg {
    width: 100%;
    height: 100%;
    object-fit: contain;
    /* add drop shadow to img */
    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.5));

}

.loadingBarBackground {
    width: 100%;
    height: 5px;
    background: white;
    position: absolute;
    animation: oreo-color-anim-bar-inv 4s infinite;

    bottom: 0;
}

.loadingBar {
    height: 100%;
    background: #bebebe;
    /* animation: oreo-color-anim-bar 4s infinite; */
    width: 0;
}

@keyframes slide {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

.loadingBar {
    animation: slide 4000ms linear; /* This will be reset and reapplied in JS */
}

.carouselContainer {
    width: 100%;
    height: 40vh;
    overflow: hidden; /* This ensures that only one item is visible */
    position: relative;
}
  
.carouselInner {
    display: flex;
    width: calc(100% * var(--number-of-items)); /* This will be set dynamically based on JS */
    height: 100%;
    transition: transform 0.4s ease-in-out;
}

.carouselItem {
    width: 100%;
    height: 100%;
    flex-shrink: 0; /* Prevents the items from shrinking */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 24px;
}

.carouselItem a {
    width: 50%;
}

.carouselItemImg {
    width: 100vw;
    height: -webkit-fill-available;
    object-fit: cover;
}

.carouselItemText {
    font-family: Union;
    color: black;
    /* font-weight: bold; */
    text-shadow: rgba(255, 255, 255, 0.8) 0px 0px 10px, rgba(255, 255, 255, 0.8) 0px 0px 5px, rgba(255, 255, 255, 0.8) 0px 0px 10px;
    position: absolute;
    bottom: 10px;
    left: auto;
    width: max-content;
    padding-left: 5px;
}
  
/* desktop */
@media only screen and (min-width: 480px) {
    .splashContent {
        width: 70%;
    }

    .splashSectionHeader {
        font-size: 24px;
    }

    .carouselItemText {
        font-size: 20px;
    }
}

/* mobile */
@media only screen and (max-width: 480px) {
    .splashContent {
        width: 90%;
    }

    .splashSectionHeader {
        font-size: 18px;
    }


    .carouselItemText {
        font-size: 15px;
    }
}