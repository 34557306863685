@keyframes glow {
    0% { box-shadow: 0px 0px 20px rgba(240, 24, 24, 0.7), 0px 0px 10px rgba(240, 24, 24, 0.5); }
    20% { box-shadow: 0px 0px 20px rgba(255, 0, 200, 0.7), 0px 0px 10px rgba(255, 0, 200, 0.5); }
    40% { box-shadow: 0px 0px 20px rgba(240, 24, 24, 0.7), 0px 0px 10px rgba(240, 24, 24, 0.5); }
    
    60% { box-shadow: 0px 0px 20px rgba(15, 231, 231, 0.7), 0px 0px 10px rgba(15, 231, 231, 0.5); }
    80% { box-shadow: 0px 0px 20px rgba(0, 255, 55, 0.7), 0px 0px 10px rgba(0, 255, 55, 0.5); }
    100% { box-shadow: 0px 0px 20px rgba(240, 24, 24, 0.7), 0px 0px 10px rgba(240, 24, 24, 0.5); }
}  

@font-face {
    font-family: Union;
    font-weight: regular;
    src: url("./fonts/Union-Regular.otf") format("opentype");
}

.BallLandingContainer {
    width: 100vw;
    height: 100vh;
    position: absolute;

    /* prevent scrolling */
    overflow: hidden !important;

    opacity: 1.0;

    touch-action: none;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.InputBox {
    width: fit-content;
    max-width: 90vw;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    transform: translate(-50%, -50%);

    animation: glow 8s infinite;

    display: flex;
    align-items: stretch;
}

.ShillMessage {
    font-size: 30px;
    font-weight: bold;
    font-family: 'Union';
    color: rgb(55, 144, 55);
    /* backdrop-filter: blur(5px) */
    background: white;
    padding: 5px;
    border: 5px solid green;
    top: 65vh;
    left: 50%;
    text-align: center;
    position: absolute;
    transform: translate(-50%, -50%);
    /* text-shadow: 0px 0px 5px #ffffff, 0px 0px 5px #ffffff, 0px 0px 10px #ffffff; */
}

.InputBox input {
    font-size: 30px;
    width: 100%;
}

.bg-ball {
    z-index: -1;
    background-image: url("./images/shillballbg.jpg") !important;
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0px;
    top: 0px;
    padding: 1px;
    background-size: cover;
    background-position: 50% 13%;
    background-repeat: no-repeat;
}


@font-face {
    font-family: 'Duke';
    src: local('Duke'), url(./fonts/DUKECONN.TTF) format('truetype');
}

@font-face {
    font-family: Union;
    font-weight: regular;
    src: url("./fonts/Union-Regular.otf") format("opentype");
}

@keyframes oreo-color-anim {
    0% { color: rgb(0, 0, 0); }
    50% { color: rgb(255, 255, 255); }
    100% { color: rgb(0, 0, 0); }
}

@keyframes oreo-highlight-anim {
    0% { text-shadow: 0px 0px 5px rgb(255, 255, 255), 0px 0px 5px rgb(255, 255, 255), 0px 0px 5px rgb(255, 255, 255); }
    50% { text-shadow: 0px 0px 5px rgb(0, 0, 0), 0px 0px 5px rgb(0, 0, 0), 0px 0px 5px rgb(0, 0, 0); }
    100% { text-shadow: 0px 0px 5px rgb(255, 255, 255), 0px 0px 5px rgb(255, 255, 255), 0px 0px 5px rgb(255, 255, 255); }
}

@keyframes glow {
    0% { box-shadow: 0px 0px 20px rgba(240, 24, 24, 0.7), 0px 0px 10px rgba(240, 24, 24, 0.5); }
    20% { box-shadow: 0px 0px 20px rgba(255, 0, 200, 0.7), 0px 0px 10px rgba(255, 0, 200, 0.5); }
    40% { box-shadow: 0px 0px 20px rgba(240, 24, 24, 0.7), 0px 0px 10px rgba(240, 24, 24, 0.5); }
    
    60% { box-shadow: 0px 0px 20px rgba(15, 231, 231, 0.7), 0px 0px 10px rgba(15, 231, 231, 0.5); }
    80% { box-shadow: 0px 0px 20px rgba(0, 255, 55, 0.7), 0px 0px 10px rgba(0, 255, 55, 0.5); }
    100% { box-shadow: 0px 0px 20px rgba(240, 24, 24, 0.7), 0px 0px 10px rgba(240, 24, 24, 0.5); }
}  

.Etherscan-link img {
    width: 25px;
    height: 25px;
    filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.7));
}

.Ball-fullres-link a {
    color: black;
    text-decoration: none;
    text-shadow: 0px 0px 5px #ffffff, 0px 0px 20px #ffffff;
}   

.Ball-title {
    font-family: Duke;
    font-weight: bold;
    color: white;
    text-shadow: 0 0 5px black,  0 0 5px black,  0 0 5px black;
}

.Ball-header {
    display: grid;
    flex-direction: row;
    width: 80%;
    /* make first column take up 80% of the space */
    grid-template-columns: 90% 10%;
}

.Ball-info-desktop {
    width: 33vw;
    min-height: 75vh;
    height: fit-content;

    display: flex;
    justify-content: center;
    align-items: center;

    padding-top: 8vh;
    padding-bottom: 5vh;
    margin-top: 15vh;

    row-gap: 40px;
    left: 0;
    
    position: absolute;

    box-shadow: 0px 0px 30px rgb(0 0 0 / 30%);

    /*make items vertical*/
    flex-direction: column;

    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
}

.Ball-container-mobile {
    width: 100%;
    height: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: center;

    row-gap: 20px;

    position: absolute;
    top: 18vh;
    left: 0;
    right: 0;

    margin-left: auto;
    margin-right: auto;

    flex-direction: column;
}

.Ball-info-mobile {
    width: 100vw;
    height: fit-content;

    display: flex;
    /* justify-content: center; */
    align-items: center;

    padding-top: 5vh;
    padding-bottom: 5vh;

    row-gap: 20px;
    left: 0;
    
    position: relative;

    box-shadow: 0px 0px 30px rgb(0 0 0 / 30%);

    /*make items vertical*/
    flex-direction: column;

    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
}


.Ball-info-item {
    /* align left */
    text-align: left;
    width: 80%;
}

.Ball-preview-mobile {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.Ball-preview-mobile img {
    width: 90%;
    object-fit: cover;  
    filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.7));
}

.Ball-preview-desktop {
    height: 85vh;
    width: 66.66vw;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15vh;
}

.Ball-preview-desktop img {
    height: 92%;
    object-fit: contain;  
    max-width: 60vw;
    filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.7));
}


.Ball-preview {
    display: flex;
    flex-direction: column;
}

.Ball-preview img {
    margin: 0 auto;
    /* cursor: zoom-in; */
    width: 70%;
}

.Ball-lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;

    transition: opacity 1s;
  
    opacity: 0.0;
    pointer-events: none;
}

.Ball-lightbox.visible {
    opacity: 1.0;
    pointer-events: auto;
} 

.Countdown {
    font-size: large;
    font-family: Duke;
    text-shadow: 0px 0px 5px #ffffff, 0px 0px 20px #ffffff, 0px 0px 20px #ffffff;
    animation: oreo-color-anim 4s infinite, oreo-highlight-anim 4s infinite;
}

.BallText {
    text-align: left;
    line-height: 1.5;
    border-radius: 15px;
    text-shadow: 0px 0px 5px #ffffff, 0px 0px 20px #ffffff;
}

/* .BallCarouselItem {
    user-select: none;
    display: flex !important;
    flex-direction: column;
    row-gap: 20px;
    outline: none;
}

.BallCarouselItemText {
    text-shadow: 0px 0px 5px #ffffff, 0px 0px 10px #ffffff, 0px 0px 10px #ffffff, 0px 0px 20px #ffffff;
    width: 80%;
    margin: 0 auto;
}

.BallCarouselItem img {
    margin: 0 auto;
    cursor: zoom-in;
} */

.Address-pill-ball {
    color: black;
    z-index: 2;
    border: 1px solid #3f3f3f;
    backdrop-filter: blur(5px);
    background-color: rgba(255,255,255,0.2);
    box-shadow: 0px 0px 30px #55ffe871;
    position: fixed;
    border-radius: 5px;
    left: 3vw;
    top: 18vh;
    padding: 10px;
    border-radius: 10px;
}

.Connect-button-ball {
    width: max-content;
    left: 3vw;
    top: 18vh;

    color: black;

    font-family: Union;
  
    z-index: 2;
    border: 1px solid #3f3f3f;
    backdrop-filter: blur(5px);
    background-color: rgba(255,255,255,0.2);
    position: fixed;
    border-radius: 5px;
    padding: 10px;
    border-radius: 10px;
  
    text-shadow: 0px 0px 5px #ffffff, 0px 0px 10px #ffffff, 0px 0px 10px #ffffff;
  
    animation: glow 8s infinite;  
}

.Mint-button-inactive-ball {
    color: #3a0348;
  
    border: 1px solid rgb(103, 103, 103);
    width: fit-content;
    backdrop-filter: blur(5px);
  
    font-size: 1.2em;
  
    box-shadow: 0px 0px 40px #cfcfcff5;
  
    background-color: rgba(255, 255, 255, 0.4);
   
    border-radius: 5px;
    padding: 10px;
    border-radius: 10px;  
}
  
.Mint-button-active-ball {
    color: #ffffff;
  
    border: 1px solid rgb(0, 0, 0);
    width: fit-content;
    backdrop-filter: blur(5px);
  
    font-size: 1.2em;
  
    box-shadow: 0px 0px 20px #e60c79f5, 0px 0px 30px #e60c79f5;
   
    background-color: rgb(255 122 238 / 40%);
   
    border-radius: 5px;
    padding: 10px;
    border-radius: 10px;
}

/* mobile */
@media only screen and (max-width: 1000px) {
    .Connect-button-ball {
        font-size: 13px;
    }

    .Countdown {
        font-size: 1.0em;
    }

    .BallText {
        font-size: 0.8em;
    }

    .Ball-fullres-link a {
        font-size: 0.8em;
    }

    .Address-pill-ball {
        font-size: 13px;
    }

    .BallContainer {
        width: 90%;
        flex-direction: vertical;
    }

    .Ball-lightbox img {
        max-width: 90%;
    }    


}

/* desktop */
@media only screen and (min-width: 1000px) {
    .Ball-title {
        font-size: 1.3em;
    }

    .Connect-button-ball {
        font-size: 18px;
    }

    .Countdown {
        font-size: 1.4em;
    }

    .BallText {
        font-size: 1.2em;
    }

    .Ball-fullres-link a {
        font-size: 1.2em;
    }

    .Address-pill-ball {
        font-size: 18px;
    }

    .BallContainer {
        width: 100%;
        flex-direction: horizontal;
        /* reverse order of items */
        flex-direction: row-reverse;
    }

    .Ball-lightbox img {
        max-height: 90%;
    }    
}

