@font-face {
    font-family: Union;
    font-weight: regular;
    src: url("./fonts/Union-Regular.otf") format("opentype");
}
  
@font-face {
    font-family: Union;
    font-weight: bold;
    src: url("./fonts/Union-Bold.otf") format("opentype");
}

@font-face {
    font-family: Agrandir;
    font-weight: bold;
    src: url("./fonts/Agrandir-GrandHeavy.otf") format("opentype");
}

@font-face {
    font-family: 'Duke';
    src: local('Duke'), url(./fonts/DUKENORM.TTF) format('truetype');
}

@font-face {
    font-family: 'Duke';
    src: local('Duke'), url(./fonts/DUKEBOLD.ttf) format('truetype');
}

@keyframes color-anim {
    0% { color: rgb(240, 24, 24); }
    33% { color: rgb(255, 85, 0); }
    66% { color: rgb(255, 0, 200); }
    100% { color: rgb(240, 24, 24); }
}

@keyframes highlight-anim {
    0% { text-shadow: 0px 0px 10px rgb(252, 176, 176), 0px 0px 20px rgb(252, 176, 176); }
    33% { text-shadow: 0px 0px 10px rgb(255, 182, 146), 0px 0px 20px rgb(255, 182, 146); }
    66% { text-shadow: 0px 0px 10px rgb(249, 154, 228), 0px 0px 20px rgb(249, 154, 228); }
    100% { text-shadow: 0px 0px 10px rgb(252, 176, 176), 0px 0px 20px rgb(252, 176, 176); }
}

@keyframes oreo-color-anim {
    0% { color: rgb(0, 0, 0); }
    50% { color: rgb(255, 255, 255); }
    100% { color: rgb(0, 0, 0); }
}

@keyframes oreo-highlight-anim {
    0% { text-shadow: 0px 0px 5px rgb(255, 255, 255), 0px 0px 5px rgb(255, 255, 255), 0px 0px 5px rgb(255, 255, 255); }
    50% { text-shadow: 0px 0px 5px rgb(0, 0, 0), 0px 0px 5px rgb(0, 0, 0), 0px 0px 5px rgb(0, 0, 0); }
    100% { text-shadow: 0px 0px 5px rgb(255, 255, 255), 0px 0px 5px rgb(255, 255, 255), 0px 0px 5px rgb(255, 255, 255); }
}

.mediatexts-container {
    position: relative;
    margin-top: 20vh;
    margin-bottom: 30vh;

    font-family: Union;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.mediatexts {
    display: flex;
    align-items: center;
    justify-content: center; 
    flex-direction: column; 
    position: relative;
    row-gap: 3vh;
}

.mediatexts a {
    text-decoration: none;
    font-size: large;
    color: rgb(180 0 163);
    animation: color-anim 3s infinite, highlight-anim 5s infinite;

    font-family: Duke;
    font-weight: bold;

    text-shadow: 0px 0px 20px #ffffff, 0px 0px 20px #ffffff;
}

.videoContainer {
    /* width: 80%; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 50px;
}

.videoContainer iframe {
    max-width: 80vw;
    max-height: calc(80vw / 1.77)
    /* height: auto; */
}

.mediaCategoryHeader {
    text-shadow: 0px 0px 10px #ffffff, 0px 0px 10px #ffffff, 0px 0px 10px #ffffff;
    text-align: center;

    font-family: Duke;
    font-weight: normal;

    animation: oreo-color-anim 4s infinite, oreo-highlight-anim 4s infinite;
}

.yempejji {
    position: relative;
    text-align: center;
    line-height: 1.5;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    padding: 25px;
    width: 70%;
    margin-bottom: 30px;
    text-shadow: 0px 0px 5px #ffffff, 0px 0px 20px #ffffff;
    backdrop-filter: blur(10px);    
}

/* desktop */
@media only screen and (min-width: 480px) {
    .podcast {
        font-size: 1.3em;
    }    
}

/* mobile */
@media only screen and (max-width: 480px) {
    .podcast {
        font-size: 0.8em;
    }    
}