@font-face {
    font-family: 'Duke';
    src: local('Duke'), url(./fonts/DUKECONN.TTF) format('truetype');
}

@font-face {
    font-family: Union;
    font-weight: regular;
    src: url("./fonts/Union-Regular.otf") format("opentype");
}

@keyframes oreo-color-anim {
    0% { color: rgb(0, 0, 0); }
    50% { color: rgb(255, 255, 255); }
    100% { color: rgb(0, 0, 0); }
}

@keyframes oreo-highlight-anim {
    0% { text-shadow: 0px 0px 5px rgb(255, 255, 255), 0px 0px 5px rgb(255, 255, 255), 0px 0px 5px rgb(255, 255, 255); }
    50% { text-shadow: 0px 0px 5px rgb(0, 0, 0), 0px 0px 5px rgb(0, 0, 0), 0px 0px 5px rgb(0, 0, 0); }
    100% { text-shadow: 0px 0px 5px rgb(255, 255, 255), 0px 0px 5px rgb(255, 255, 255), 0px 0px 5px rgb(255, 255, 255); }
}

@keyframes glow {
    0% { box-shadow: 0px 0px 20px rgba(240, 24, 24, 0.7), 0px 0px 10px rgba(240, 24, 24, 0.5); }
    20% { box-shadow: 0px 0px 20px rgba(255, 0, 200, 0.7), 0px 0px 10px rgba(255, 0, 200, 0.5); }
    40% { box-shadow: 0px 0px 20px rgba(240, 24, 24, 0.7), 0px 0px 10px rgba(240, 24, 24, 0.5); }
    
    60% { box-shadow: 0px 0px 20px rgba(15, 231, 231, 0.7), 0px 0px 10px rgba(15, 231, 231, 0.5); }
    80% { box-shadow: 0px 0px 20px rgba(0, 255, 55, 0.7), 0px 0px 10px rgba(0, 255, 55, 0.5); }
    100% { box-shadow: 0px 0px 20px rgba(240, 24, 24, 0.7), 0px 0px 10px rgba(240, 24, 24, 0.5); }
}  

/* @keyframes gallery-portal-animation {
    0%, 100% {
      filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.7));
    }
    50% {
      filter: drop-shadow(0px 0px 60px rgba(255, 255, 255, 1.0));
    }
}   */

@keyframes gallery-portal-animation {
    0% {
      filter: drop-shadow(0px 0px 10px rgba(235, 116, 116, 0.7));
    }
    20% {
      filter: drop-shadow(0px 0px 10px rgba(240, 105, 211, 0.7));
    }
    40% {
      filter: drop-shadow(0px 0px 10px rgba(245, 119, 119, 0.7));
    }
    60% {
      filter: drop-shadow(0px 0px 10px rgba(88, 234, 234, 0.7));
    }
    80% {
      filter: drop-shadow(0px 0px 10px rgba(102, 255, 135, 0.7));
    }
    100% {
      filter: drop-shadow(0px 0px 10px rgba(240, 101, 101, 0.7));
    }
}
  

.bg-wanderlustGirls {
    z-index: -1;
    background-image: url("./images/splashscreen_wanderlust.jpg") !important;
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0px;
    top: 0px;
    padding: 1px;
    background-size: cover;
    background-position: 50% 13%;
    background-repeat: no-repeat;
}

.Etherscan-link-wanderlust img {
    width: 25px;
    height: 25px;
    filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.7));
    justify-content: end;
}

.WanderlustGirls-fullres-link a {
    color: black;
    text-decoration: none;
    text-shadow: 0px 0px 5px #ffffff, 0px 0px 20px #ffffff;
}   

.WanderlustGirls-title {
    font-family: Duke;
    font-weight: bold;
    color: white;
    text-shadow: 0 0 5px black,  0 0 5px black,  0 0 5px black;
}

/* .WanderlustGirlsPriceGrid {
    display:grid;
    grid-template-columns: 200px 200px;
    grid-row: auto auto;
    grid-column-gap: 20px;
    grid-row-gap: 20px;  
} */

.WanderlustGirlsPriceGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    width: 100%;
    height: auto;

    color: white;
    text-shadow: 0 0 5px black, 0 0 5px black, 0 0 5px black;
}

.WanderlustPieKeyNote {
    color: white;
    text-shadow: 0 0 5px black, 0 0 5px black, 0 0 5px black;
}

.WanderlustGirls-header {
    display: grid;
    flex-direction: row;
    width: 80%;
    /* make first column take up 80% of the space */
    grid-template-columns: 90% 10%;
}

.WanderlustGirls-info-desktop {
    width: 33vw;
    height: fit-content;

    display: flex;
    justify-content: center;
    align-items: center;

    padding-top: 8vh;
    padding-bottom: 5vh;
    margin-top: 15vh;

    row-gap: 40px;
    left: 0;
    
    position: absolute;

    box-shadow: 0px 0px 30px rgb(0 0 0 / 30%);

    /*make items vertical*/
    flex-direction: column;

    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
}

.WanderlustGirls-container-mobile {
    width: 100%;
    height: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: center;

    row-gap: 20px;

    position: absolute;
    top: 18vh;
    left: 0;
    right: 0;

    margin-left: auto;
    margin-right: auto;

    flex-direction: column;
}

.WanderlustGirls-info-mobile {
    width: 100vw;
    height: fit-content;

    display: flex;
    /* justify-content: center; */
    align-items: center;

    padding-top: 5vh;
    padding-bottom: 5vh;

    row-gap: 20px;
    left: 0;
    
    position: relative;

    box-shadow: 0px 0px 30px rgb(0 0 0 / 30%);

    /*make items vertical*/
    flex-direction: column;

    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
}

.WanderlustGirlsMintZone {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.WanderlustMintInput {
    font-size: 30px;
    text-align: center;
    width: 50px;
}

.WanderlustGirls-info-item {
    /* align left */
    text-align: left;
    width: 80%;
    display: flex;
    gap: 20px;
    flex-direction: column;
}

.WanderlustGirls-ethscan-link {
    /* align left */
    text-align: right;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 10px;
}


/* .WanderlustCarousel-img {
    width: 10%;
    height: auto;
    cursor: grab;
} */

.WanderlustGirlsLogo {
    width: 90%;
    height: auto;
    /* cursor: grab; */
}

.WanderlustGirls-preview-mobile {
    width: 90vw;
    display: block;
    position: relative;
}

.WanderlustGirls-preview-mobile img {
    width: 90%;
    object-fit: cover;  
}

.WanderlustGirls-preview-desktop {
    height: auto;
    width: 66.66vw;
    right: 0;
    display: block;
    position: absolute;
    top: 55vh;
    transform: translateY(-50%);

    text-align: center;
}

.WanderlustGirls-preview-desktop img {
    height: 92%;
    object-fit: contain;  
    max-width: 60vw;
    /* filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.7)); */
}


.WanderlustGirls-preview {
    display: flex;
    flex-direction: column;
}

.WanderlustGirls-preview img {
    margin: 0 auto;
    /* cursor: zoom-in; */
    width: 70%;
}

.WanderlustGirls-lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;

    transition: opacity 1s;
  
    opacity: 0.0;
    pointer-events: none;
}

.WanderlustGirls-lightbox.visible {
    opacity: 1.0;
    pointer-events: auto;
} 

.Countdown {
    font-size: large;
    font-family: Duke;
    text-shadow: 0px 0px 5px #ffffff, 0px 0px 20px #ffffff, 0px 0px 20px #ffffff;
    animation: oreo-color-anim 4s infinite, oreo-highlight-anim 4s infinite;
}

.WanderlustGirlsText {
    text-align: left;
    line-height: 1.5;
    border-radius: 15px;
    /* text-shadow: 0px 0px 5px #ffffff, 0px 0px 20px #ffffff; */

    color: white;
    text-shadow: 0 0 5px black, 0 0 5px black, 0 0 5px black;
}

.WanderlustGirlsText a {
    color: pink;
}

/* .WanderlustGirlsCarouselItem {
    user-select: none;
    display: flex !important;
    flex-direction: column;
    row-gap: 20px;
    outline: none;
}

.WanderlustGirlsCarouselItemText {
    text-shadow: 0px 0px 5px #ffffff, 0px 0px 10px #ffffff, 0px 0px 10px #ffffff, 0px 0px 20px #ffffff;
    width: 80%;
    margin: 0 auto;
}

.WanderlustGirlsCarouselItem img {
    margin: 0 auto;
    cursor: zoom-in;
} */

.Address-pill-wanderlustGirls {
    color: black;
    z-index: 2;
    border: 1px solid #3f3f3f;
    backdrop-filter: blur(5px);
    background-color: rgba(255,255,255,0.2);
    box-shadow: 0px 0px 30px #55ffe871;
    position: fixed;
    border-radius: 5px;
    left: 3vw;
    top: 18vh;
    padding: 10px;
    border-radius: 10px;
}

.Connect-button-wanderlustGirls {
    width: max-content;
    left: 3vw;
    top: 18vh;

    color: black;

    font-family: Union;
  
    z-index: 2;
    border: 1px solid #3f3f3f;
    backdrop-filter: blur(5px);
    background-color: rgba(255,255,255,0.2);
    position: fixed;
    border-radius: 5px;
    padding: 10px;
    border-radius: 10px;
  
    text-shadow: 0px 0px 5px #ffffff, 0px 0px 10px #ffffff, 0px 0px 10px #ffffff;
  
    animation: glow 8s infinite;  
}

.Enter-gallery-wanderlust {
    width: max-content;
    right: 4vw;
    top: 11vh;

    color: black;

    font-family: Union;
  
    z-index: 10;
    background: none;
    position: fixed;
    padding: 10px;

    font-size: 0.8em;

    line-height: 1.3;

    text-decoration: none;
  
    text-shadow: 0px 0px 5px #ffffff, 0px 0px 10px #ffffff, 0px 0px 10px #ffffff;

    filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.7));
    /* animation: glow 8s infinite;   */
    animation: gallery-portal-animation 8s infinite;

}

.EnterYechellesText {
    font-family: Duke;
    text-align: center;
    
    font-weight: bold;
    text-shadow: 0px 0px 5px #ffffff, 0px 0px 10px #ffffff, 0px 0px 10px #ffffff;
}

.EnterGallery {
    display: flex;
    flex-direction: row;

    align-items: center;
}

.EnterGalleryMobile {
    display: flex;
    flex-direction: row;

    font-size: 0.6em; 

    align-items: center;
}


.EnterGalleryImage {
    width: 100px;
    object-fit: cover;
    border-radius: 5px;
}

.EnterGalleryImage-mobile {
    width: 45px;
    object-fit: cover;
    border-radius: 5px;
}



.Mint-button-inactive-wanderlustGirls {
    color: #3a0348;
  
    border: 1px solid rgb(103, 103, 103);
    width: fit-content;
    backdrop-filter: blur(5px);
  
    font-size: 1.2em;
  
    box-shadow: 0px 0px 40px #cfcfcff5;
  
    background-color: rgba(255, 255, 255, 0.4);
   
    border-radius: 5px;
    padding: 10px;
    border-radius: 10px;  
}
  
.Mint-button-active-wanderlustGirls {
    color: #ffffff;
  
    border: 1px solid rgb(0, 0, 0);
    width: fit-content;
    backdrop-filter: blur(5px);
  
    font-size: 1.2em;
  
    box-shadow: 0px 0px 20px #e60c79f5, 0px 0px 30px #e60c79f5;
   
    background-color: rgb(255 122 238 / 40%);
   
    border-radius: 5px;
    padding: 10px;
    border-radius: 10px;
}

/* mobile */
@media only screen and (max-width: 1000px) {
    .Connect-button-wanderlustGirls {
        font-size: 13px;
    }

    .Countdown {
        font-size: 1.0em;
    }

    .WanderlustGirlsText {
        font-size: 1.0em;
    }

    .WanderlustGirls-fullres-link a {
        font-size: 0.8em;
    }

    .Address-pill-wanderlustGirls {
        font-size: 13px;
    }

    .WanderlustGirlsContainer {
        width: 90%;
        flex-direction: vertical;
    }

    .WanderlustGirls-lightbox img {
        max-width: 90%;
    }    


}

/* desktop */
@media only screen and (min-width: 1000px) {
    .WanderlustGirls-title {
        font-size: 1.3em;
    }

    .Connect-button-wanderlustGirls {
        font-size: 18px;
    }

    .Countdown {
        font-size: 1.4em;
    }

    .WanderlustGirlsText {
        font-size: 1.2em;
    }

    .WanderlustGirls-fullres-link a {
        font-size: 1.2em;
    }

    .Address-pill-wanderlustGirls {
        font-size: 18px;
    }

    .WanderlustGirlsContainer {
        width: 100%;
        flex-direction: horizontal;
        /* reverse order of items */
        flex-direction: row-reverse;
    }

    .WanderlustGirls-lightbox img {
        max-height: 90%;
    }    
}

