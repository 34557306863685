@font-face {
    font-family: Union;
    font-weight: regular;
    src: url("./fonts/Union-Regular.otf") format("opentype");
}
  
@font-face {
    font-family: Union;
    font-weight: bold;
    src: url("./fonts/Union-Bold.otf") format("opentype");
}

@font-face {
    font-family: 'Pantasia';
    src: url(./fonts/Pantasia-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Duke';
    src: local('Duke'), url(./fonts/DUKECONN.TTF) format('truetype');
}

@font-face {
    font-family: Agrandir;
    font-weight: bold;
    src: url("./fonts/Agrandir-GrandHeavy.otf") format("opentype");
}

@keyframes oreo-color-anim {
    0% { color: rgb(0, 0, 0); }
    50% { color: rgb(255, 255, 255); }
    100% { color: rgb(0, 0, 0); }
}

@keyframes oreo-highlight-anim {
    0% { text-shadow: 0px 0px 5px rgb(255, 255, 255), 0px 0px 5px rgb(255, 255, 255), 0px 0px 5px rgb(255, 255, 255); }
    50% { text-shadow: 0px 0px 5px rgb(0, 0, 0), 0px 0px 5px rgb(0, 0, 0), 0px 0px 5px rgb(0, 0, 0); }
    100% { text-shadow: 0px 0px 5px rgb(255, 255, 255), 0px 0px 5px rgb(255, 255, 255), 0px 0px 5px rgb(255, 255, 255); }
}

.collection-type {
    font-family: Duke;
    font-weight: bold;
    text-shadow: 0px 0px 20px #ffffff, 0px 0px 20px #ffffff, 0px 0px 20px #ffffff;
    width: 100%;
    font-size: 2em;
    text-align: center;
}

.collections-container {
    position: relative;
    font-family: Union;

    margin-top: 20vh;
    margin-bottom: 10%;
    width: 100%;
    height: 100%;
    padding-top: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.collections-group {
    display: flex;
    align-items: center;
    justify-content: center; 
    flex-direction: column; 
    position: relative;
    row-gap: 3vh;

    width: 50%;
}

.collections {
    display: flex;
    align-items: baseline;
    justify-content: center;  
    position: relative;
    row-gap: 3vh;
}

.collection-item {
    text-align: center;
    line-height: 1.2;
    width: 100%;
}

.collection-item a {
    text-decoration: none;
    font-family: Duke;
    font-weight: normal;
}

.exhibition-link {
    text-decoration: none;
    color:#000;
    text-shadow: 0px 0px 20px #ffffff, 0px 0px 20px #ffffff;

    animation: oreo-color-anim 4s infinite, oreo-highlight-anim 4s infinite;
}

.collection-link {
    text-decoration: none;
    color: #000;
    text-shadow: 0px 0px 20px #ffffff, 0px 0px 20px #ffffff;

    animation: oreo-color-anim 4s infinite, oreo-highlight-anim 4s infinite;
}

/* desktop */
@media only screen and (min-width: 480px) {
    .collections {
        flex-direction: row;
        width: 70%;
    }

    .collection-item img {
        width: 70%;
    }   

    .collection-item a {
        font-size: 1.2em;
    }
}

/* mobile */
@media only screen and (max-width: 480px) {
    .collection-type {
        font-size: 1.3em;
    }

    .collections-container {
        margin-top: 50%;
        margin-bottom: 35%;
    }
    .collections {
        flex-direction: row;
        width: 90%;
    }
    .collection-item img {
        width: 90%;
    }  
    .collection-item a {
        font-size: 15px;
    }
}