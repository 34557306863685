@font-face {
    font-family: 'Duke';
    src: local('Duke'), url(./fonts/DUKECONN.TTF) format('truetype');
}

@font-face {
    font-family: Union;
    font-weight: regular;
    src: url("./fonts/Union-Regular.otf") format("opentype");
}

@keyframes oreo-color-anim {
    0% { color: rgb(0, 0, 0); }
    50% { color: rgb(255, 255, 255); }
    100% { color: rgb(0, 0, 0); }
}

@keyframes oreo-highlight-anim {
    0% { text-shadow: 0px 0px 5px rgb(255, 255, 255), 0px 0px 5px rgb(255, 255, 255), 0px 0px 5px rgb(255, 255, 255); }
    50% { text-shadow: 0px 0px 5px rgb(0, 0, 0), 0px 0px 5px rgb(0, 0, 0), 0px 0px 5px rgb(0, 0, 0); }
    100% { text-shadow: 0px 0px 5px rgb(255, 255, 255), 0px 0px 5px rgb(255, 255, 255), 0px 0px 5px rgb(255, 255, 255); }
}

@keyframes glow {
    0% { box-shadow: 0px 0px 20px rgba(240, 24, 24, 0.7), 0px 0px 10px rgba(240, 24, 24, 0.5); }
    20% { box-shadow: 0px 0px 20px rgba(255, 0, 200, 0.7), 0px 0px 10px rgba(255, 0, 200, 0.5); }
    40% { box-shadow: 0px 0px 20px rgba(240, 24, 24, 0.7), 0px 0px 10px rgba(240, 24, 24, 0.5); }
    
    60% { box-shadow: 0px 0px 20px rgba(15, 231, 231, 0.7), 0px 0px 10px rgba(15, 231, 231, 0.5); }
    80% { box-shadow: 0px 0px 20px rgba(0, 255, 55, 0.7), 0px 0px 10px rgba(0, 255, 55, 0.5); }
    100% { box-shadow: 0px 0px 20px rgba(240, 24, 24, 0.7), 0px 0px 10px rgba(240, 24, 24, 0.5); }
}  

.bg-giveaway {
    z-index: -1;
    background-image: url("./images/2023_bg_1.jpg") !important;
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0px;
    top: 0px;
    padding: 1px;
    background-size: cover;
    background-position: 50% 13%;
    background-repeat: no-repeat;
}

.Preview-carousel-giveaway {
    position: relative;
    margin-left: 0; /* Half of the element's width */
    left: 0;
    overflow: hidden;
}

.GiveawayContainer {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    row-gap: 20px;

    top: 20%;
    position: absolute;

    /* center div horizontally */
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    
    padding-bottom: 20vh;

    /*make items vertical*/
    flex-direction: column;
}

.Giveaway-lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;

    transition: opacity 1s;
  
    opacity: 0.0;
    pointer-events: none;
}

.Giveaway-lightbox.visible {
    opacity: 1.0;
    pointer-events: auto;
} 

.Countdown {
    font-size: large;
    font-family: Duke;
    text-shadow: 0px 0px 5px #ffffff, 0px 0px 20px #ffffff, 0px 0px 20px #ffffff;
    animation: oreo-color-anim 4s infinite, oreo-highlight-anim 4s infinite;
}

.GiveawayText {
    text-align: left;
    line-height: 1.5;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    padding: 45px;
    margin: 20px;
    width: 70%;
    margin: 0 auto;
    text-shadow: 0px 0px 5px #ffffff, 0px 0px 20px #ffffff;
    backdrop-filter: blur(10px);
}

.GiveawayCarouselItem {
    user-select: none;
    display: flex !important;
    flex-direction: column;
    /* add gap between items */
    row-gap: 20px;
    outline: none;
}

.GiveawayCarouselItemText {
    text-shadow: 0px 0px 5px #ffffff, 0px 0px 10px #ffffff, 0px 0px 10px #ffffff, 0px 0px 20px #ffffff;
    width: 80%;
    margin: 0 auto;
}

.GiveawayCarouselItem img {
    margin: 0 auto;
    cursor: zoom-in;
}

.Address-pill-giveaway {
    color: black;
    z-index: 2;
    border: 1px solid #3f3f3f;
    backdrop-filter: blur(5px);
    background-color: rgba(255,255,255,0.2);
    box-shadow: 0px 0px 30px #55ffe871;
    position: fixed;
    border-radius: 5px;
    left: 3vw;
    top: 18vh;
    padding: 10px;
    border-radius: 10px;
}

.Connect-button-giveaway {
    width: max-content;
    left: 3vw;
    top: 18vh;

    color: black;

    font-family: Union;
  
    z-index: 2;
    border: 1px solid #3f3f3f;
    backdrop-filter: blur(5px);
    background-color: rgba(255,255,255,0.2);
    position: fixed;
    border-radius: 5px;
    padding: 10px;
    border-radius: 10px;
  
    text-shadow: 0px 0px 5px #ffffff, 0px 0px 10px #ffffff, 0px 0px 10px #ffffff;
  
    animation: glow 8s infinite;  
}

.Mint-button-inactive-giveaway {
    color: #3a0348;
  
    border: 1px solid rgb(103, 103, 103);
    width: fit-content;
    backdrop-filter: blur(5px);
  
    font-size: 1.2em;
  
    box-shadow: 0px 0px 40px #cfcfcff5;
  
    background-color: rgba(255, 255, 255, 0.4);
   
    border-radius: 5px;
    padding: 10px;
    border-radius: 10px;  
}
  
.Mint-button-active-giveaway {
    color: #ffffff;
  
    border: 1px solid rgb(0, 0, 0);
    width: fit-content;
    backdrop-filter: blur(5px);
  
    font-size: 1.2em;
  
    box-shadow: 0px 0px 20px #e60c79f5, 0px 0px 30px #e60c79f5;
   
    background-color: rgb(255 122 238 / 40%);
   
    border-radius: 5px;
    padding: 10px;
    border-radius: 10px;
}

/* mobile */
@media only screen and (max-width: 1000px) {
    .Connect-button-giveaway {
        font-size: 13px;
    }

    .Countdown {
        font-size: 1.0em;
    }

    .GiveawayText {
        font-size: 0.8em;
    }

    .Address-pill-giveaway {
        font-size: 13px;
    }

    .GiveawayContainer {
        width: 90%;
    }

    .Giveaway-lightbox img {
        max-width: 90%;
    }    

    .Preview-carousel-giveaway {
        width: 100%;
    }

    .GiveawayCarouselItem img {
        height: 64%;
        width: auto;
    }

    .GiveawayCarouselItem {
        height: 450px;
    }

}

/* desktop */
@media only screen and (min-width: 1000px) {
    .Connect-button-giveaway {
        font-size: 18px;
    }

    .Countdown {
        font-size: 1.4em;
    }

    .GiveawayText {
        font-size: 1.2em;
    }

    .Address-pill-giveaway {
        font-size: 18px;
    }

    .GiveawayContainer {
        width: 50%;
    }

    .Giveaway-lightbox img {
        max-height: 90%;
    }    

    .GiveawayCarouselItem img {
        height: 80%;
        width: auto;
    }

    .Preview-carousel-giveaway {
        width: 100%;
    }

    .GiveawayCarouselItem {
        height: 650px;
    }
}

