@font-face {
    font-family: 'Duke';
    src: local('Duke'), url(./fonts/DUKECONN.TTF) format('truetype');
}

@font-face {
    font-family: 'Duke';
    src: local('Duke'), url(./fonts/DUKENORM.TTF) format('truetype');
}

@font-face {
    font-family: 'Duke';
    src: local('Duke'), url(./fonts/DUKEBOLD.ttf) format('truetype');
}

@keyframes gallery-portal-animation {
    0% {
      filter: drop-shadow(0px 0px 10px rgba(235, 116, 116, 0.7));
    }
    20% {
      filter: drop-shadow(0px 0px 10px rgba(240, 105, 211, 0.7));
    }
    40% {
      filter: drop-shadow(0px 0px 10px rgba(245, 119, 119, 0.7));
    }
    60% {
      filter: drop-shadow(0px 0px 10px rgba(88, 234, 234, 0.7));
    }
    80% {
      filter: drop-shadow(0px 0px 10px rgba(102, 255, 135, 0.7));
    }
    100% {
      filter: drop-shadow(0px 0px 10px rgba(240, 101, 101, 0.7));
    }
}

.split-screen {
    display: flex;
    height: 100vh;
}

.ObaaComingSoonContainer {
    width: 50%;
    height: 100%;
}

.NomemeneComingSoonContainer {
    width: 50%;
    height: 100%;
    background-color:rgba(235, 116, 116, 0.7);
}

.NomemeneTeaser {
    width: 100%;
    height: 100%;

    background-image: url("./images/nomemene_teaser.jpg") !important;
    background-size: cover;
    background-position: center;
}

.full-screen-video {
    /* position: fixed; */
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.background-video {
    position: absolute;
    top: 0;
    left: 50%;
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    z-index: -1;
    transform: translateX(-50%);
}

/* center in the screen*/
.ObaaComingSoon {
    position: absolute;
    top: 50%;
    /* left: 50%; */
    left: 25%;
    transform: translate(-50%, -50%);

    display: flex;

    flex-direction: column;

    align-items: center;
    justify-content: center;

    row-gap: 15px;
}

.ObaaComingSoonText {
    text-shadow: 0px 0px 20px #ffffff, 0px 0px 20px #ffffff;
    animation: oreo-color-anim 4s infinite, oreo-highlight-anim 4s infinite;
    font-family: duke;
    font-weight: bold;
}

.ObaaLogo {
    width: 80%;
}

.ObaaLogo img {
    width: 100%;
    height: auto;
    object-fit: contain;

    cursor: pointer;

    /* add a drop shadow around the image */
    filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.4));
}

.ObaaLogo a {
    cursor: pointer !important;
}

.Enter-gallery-obaa-splash {
    width: max-content;
    right: 4vw;
    top: 20vh;

    color: black;

    font-family: Union;
  
    z-index: 10;
    background: none;
    position: fixed;
    padding: 10px;

    font-size: 0.8em;

    line-height: 1.3;

    text-decoration: none;
  
    text-shadow: 0px 0px 5px #ffffff, 0px 0px 10px #ffffff, 0px 0px 10px #ffffff;

    filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.7));
    /* animation: glow 8s infinite;   */
    animation: gallery-portal-animation 8s infinite;
}

.EnterObaaGallery {
    display: flex;
    flex-direction: row;

    align-items: center;
}

.EnterGalleryObaaImage {
    width: 300px;
    object-fit: cover;
    border-radius: 5px;
}

.EnterGalleryObaaImage-mobile {
    width: 150px;
    object-fit: cover;
    border-radius: 5px;
}

/* desktop */
@media only screen and (min-width: 480px) {
    .ObaaComingSoon {
        width: 50vw;
    }

    .ObaaComingSoonText {
        font-size: x-large;
    }
}

/* mobile */
@media only screen and (max-width: 480px) {
    .ObaaComingSoon {
        width: 50vw;
    }

    .ObaaComingSoonText {
        font-size: large;
    }
}