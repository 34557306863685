
/* 

body {
    cursor: url('../images/chewed_128.png') 25 120, url('../images/chewed_128.cur') 25 120, auto !important;
} */
  
:root {
    --hide-mobile-overlay-height: calc(40vh);
    /* --show-mobile-overlay-height: 500px; */
    --show-mobile-overlay-height: calc(100vh - 20vh);

    --hide-desktop-overlay-top-wanderlust: calc(100vh - 370px);
    --show-desktop-overlay-top: 25vh;
}

@font-face {
    font-family: 'Duke';
    src: local('Duke'), url('../fonts/DUKECONN.TTF') format('truetype');
}

@font-face {
    font-family: Union;
    font-weight: regular;
    src: url("../fonts/Union-Regular.otf") format("opentype");
}
  
@font-face {
    font-family: Union;
    font-weight: bold;
    src: url("../fonts/Union-Bold.otf") format("opentype");
}

@keyframes glow {
    0% { box-shadow: 0px 0px 20px rgba(240, 24, 24, 0.7), 0px 0px 10px rgba(240, 24, 24, 0.5); }
    20% { box-shadow: 0px 0px 20px rgba(255, 0, 200, 0.7), 0px 0px 10px rgba(255, 0, 200, 0.5); }
    40% { box-shadow: 0px 0px 20px rgba(240, 24, 24, 0.7), 0px 0px 10px rgba(240, 24, 24, 0.5); }
    
    60% { box-shadow: 0px 0px 20px rgba(15, 231, 231, 0.7), 0px 0px 10px rgba(15, 231, 231, 0.5); }
    80% { box-shadow: 0px 0px 20px rgba(0, 255, 55, 0.7), 0px 0px 10px rgba(0, 255, 55, 0.5); }
    100% { box-shadow: 0px 0px 20px rgba(240, 24, 24, 0.7), 0px 0px 10px rgba(240, 24, 24, 0.5); }
}  


@keyframes glow-switch {
    0% { background-color: #f01818; box-shadow: 0px 0px 20px rgba(240, 24, 24, 0.7), 0px 0px 10px rgba(240, 24, 24, 0.5); }
    20% { background-color: #ff00c8; box-shadow: 0px 0px 20px rgba(255, 0, 200, 0.7), 0px 0px 10px rgba(255, 0, 200, 0.5); }
    40% { background-color: #f01818; box-shadow: 0px 0px 20px rgba(240, 24, 24, 0.7), 0px 0px 10px rgba(240, 24, 24, 0.5); }
    
    60% { background-color: #0fe7e7; box-shadow: 0px 0px 20px rgba(15, 231, 231, 0.7), 0px 0px 10px rgba(15, 231, 231, 0.5); }
    80% { background-color: #00ff37; box-shadow: 0px 0px 20px rgba(0, 255, 55, 0.7), 0px 0px 10px rgba(0, 255, 55, 0.5); }
    100% { background-color: #f01818; box-shadow: 0px 0px 20px rgba(240, 24, 24, 0.7), 0px 0px 10px rgba(240, 24, 24, 0.5); }
}  

/* create an animation the same as glow but with text-shadow instead of box-shadow */
@keyframes glowText {
    0% { text-shadow: 0px 0px 10px rgba(240, 24, 24, 0.7), 0px 0px 5px rgba(240, 24, 24, 0.5); }
    20% { text-shadow: 0px 0px 10px rgba(255, 0, 200, 0.7), 0px 0px 5px rgba(255, 0, 200, 0.5); }
    40% { text-shadow: 0px 0px 10px rgba(240, 24, 24, 0.7), 0px 0px 5px rgba(240, 24, 24, 0.5); }
    
    60% { text-shadow: 0px 0px 10px rgba(15, 231, 231, 0.7), 0px 0px 5px rgba(15, 231, 231, 0.5); }
    80% { text-shadow: 0px 0px 10px rgba(0, 255, 55, 0.7), 0px 0px 5px rgba(0, 255, 55, 0.5); }
    100% { text-shadow: 0px 0px 10px rgba(240, 24, 24, 0.7), 0px 0px 5px rgba(240, 24, 24, 0.5); }
}

@keyframes fadeInAnim {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOutAnim {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes hueRotateAnim {
    from {
        filter: hue-rotate(0deg);
        opacity: 1.0;
    }
    to {
        filter: hue-rotate(360deg);
        opacity: 1.0;
    }
}

/* add an animation that pulses the width and height from 35px to 55px */
@keyframes pulseAnim {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.4);
    }
    100% {
        transform: scale(1);
    }
}

iframe {
    border: none;
    background: none;
}

.switchContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
    right: 3vw;

    z-index: 1000;
    
    padding: 5px;
    
    /* border-radius: 5px;
    background: rgb(255 255 255 / 10%);
    backdrop-filter: blur(5px); */

    display: none;
}

.switchContainer-desktop {
    top: 22vh;
}

.switchContainer-mobile {
    top: 17vh;
}

.switchLabel {
    font-size: 13px;
    font-family: union;
    animation: glowText 8s infinite;
}

.showBidsSwitch {
    margin: 10px;
    animation: glow 8s infinite;
}

.lds-ripple {
    display: inline-block;
    position: absolute;
    width: 80px;
    height: 80px;
    left: 50%;
    transform: translate(-50%, -50%);

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.lds-ripple-desktop {
    top: 80%;
}

.lds-ripple-mobile {
    top: 70%;
}


.lds-ripple div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }
    4.9% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }
    5% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}

.WanderlustMintButtons {
    display: flex;
    padding: 10px;
    justify-content: space-between;
}

.WanderlustMintButton {
    border: 1px solid rgb(20, 20, 20);
    border-radius: 5px;
    padding: 2.5px;
    text-align: center;
    width: max-content;
    /* backdrop-filter: blur(5px); */
    background-color: rgba(255,255,255,0.2);
    animation: glow 8s infinite;  
    white-space: nowrap;
}

.WanderlustGirlsPriceGridYechelles {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    width: 100%;
    height: auto;
}

.Tutorial-dark {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    z-index: 100000;
    opacity: 0;
    cursor: none;
    display: none;
    height: auto;
    box-shadow: 0 0 200px white;
    /* pointer-events: none; */
    /* disable scrolling */
    overflow: hidden;   
}

.Tutorial-dark video {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.Tutorial-desktop {
    /* create a div centered in the screen with a backrgound image that takes up 50% of the screen */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 50%;
    z-index: 100000;
    opacity: 0;
    cursor: none;
    /* pointer-events: none; */
    
    background-image: url("../images/desktop.webp");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    /* disable scrolling */
    overflow: hidden;   
}

.Tutorial-mobile {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 60%;
    background-image: url("../images/mobile.webp");
    z-index: 100000;
    opacity: 0;

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
  
.Artist-list-toggle {
    position: absolute;
    width: auto;
    /* min-width: 300px; */
    left: 0;
    z-index: 1000;

    border: 1px solid black;
    
    text-shadow: 0px 0px 5px rgb(255, 255, 255);
    
    background: rgb(255 255 255 / 10%);
    backdrop-filter: blur(5px);

    /* box-shadow: 0 0 20px 0 rgba(0,0,0,0.5); */

    cursor: none;

    font-family: Duke;
    font-size: 25px;
    /* font-weight: bold; */

    padding: 10px;
    margin: 10px;

    color: black;

    animation: glow 8s infinite;  
}

.Artist-list-toggle-desktop {
    top: 15vh;
}

.Artist-list-toggle-mobile {
    top: 8vh;
}

.Artist-list-toggle button {
    color: black;
}

.Artist-list-desktop {
    display: flex;
    /* backdrop-filter: blur(10px); */
    width: 100vw;
    height: 100vh;

    -webkit-mask-image: linear-gradient(to bottom, black 80%, transparent 100%);
    mask-image: linear-gradient(to bottom, black 80%, transparent 100%);  

    cursor: none;

    /* padding: 20px; */
    box-shadow: 0 0 30px 0 rgba(0,0,0,0.5);

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    -webkit-filter: drop-shadow(0px 0px 5px rgba(0,0,0,0.5));
    filter: drop-shadow(0px 0px 5px rgba(0,0,0,0.5));
    
    background: rgba(255,255,255,0.3);
    
    z-index: 100;
    position: absolute;
    overflow: scroll;
    /* overflow: hidden !important; */


    /* center children horizontally and vertically */
    justify-content: center;
    align-items: center;
}

.Connect-button-wanderlust {
    width: max-content;
    right: 3vw;

    color: black;

    font-family: Union;

    cursor: none;
  
    z-index: 1000;
    border: 1px solid #3f3f3f;
    backdrop-filter: blur(5px);
    background-color: rgba(255,255,255,0.2);
    position: fixed;
    border-radius: 5px;
    padding: 10px;
    border-radius: 5px;
  
    text-shadow: 0px 0px 5px #ffffff, 0px 0px 10px #ffffff, 0px 0px 10px #ffffff;
  
    animation: glow 8s infinite;  
}

.Connect-button-wanderlust-mobile {
    top: 13vh;
}

.Connect-button-wanderlust-desktop {
    top: 18vh;
}

.Address-pill-wanderlust {
    color: black;
    z-index: 2;
    border: 1px solid #3f3f3f;
    backdrop-filter: blur(5px);
    background-color: rgba(255,255,255,0.2);
    box-shadow: 0px 0px 30px #55ffe871;
    position: fixed;
    border-radius: 5px;
    right: 3vw;
    top: 18vh;
    padding: 10px;
    border-radius: 5px;
}

.Mobile-blur-overlay {
    position: absolute;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(10px);
    background: rgba(255,255,255,0.5);
    z-index: 50;
}

.Desktop-blur-overlay {
    position: absolute;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(10px);
    background: rgba(255,255,255,0.5);
    z-index: 50;
}

.Artist-list-desktop-container {
    position: absolute;
    display: none;
    cursor: none;
    width: 100vw;
    height: 100vh;
    z-index: 8;
}

.Artist-list-mobile-container {
    position: absolute;
    display: none;
    width: 100vw;
    height: 100vh;
    z-index: 8;
}
  
.Artist-list-mobile {
    display: flex;
    /* backdrop-filter: blur(10px); */
    width: 100vw;
    height: 100vh;

    cursor: none;

    /* padding: 20px; */
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, 0%);
    /* -webkit-filter: drop-shadow(0px 0px 5px rgba(0,0,0,0.5));
    filter: drop-shadow(0px 0px 5px rgba(0,0,0,0.5)); */

    z-index: 100;
    overflow: scroll;

    /* center children horizontally and vertically */
    justify-content: center;
    align-items: center;

    -webkit-mask-image: linear-gradient(to bottom, black 75%, transparent 95%);
    mask-image: linear-gradient(to bottom, black 75%, transparent 95%);  
}

.Artist-list-inner-desktop {
    width: auto;
    height: auto;
    
    display: grid;

    top: 20vh;

    padding-bottom: 20vh;

    grid-template-columns: repeat(auto-fill, minmax(15vw, 1fr));

    grid-column-gap: 5px;

    position: absolute;
    overflow: scroll;
}

.Artist-list-inner-mobile {
    width: 100vw;
    height: auto;  

    padding-bottom: 20vh;

    z-index: 100;

    top: 20vh;

    display: grid;
    grid-template-columns: auto;  

    position: absolute;
    overflow: scroll;
}

.Artist-group {
    display: flex;
    flex-direction: column;
    /* border: solid 1px rgba(0, 0, 0, 0.1); */
    border: none;
    margin: 0px;

    /* center items horizontally and vertically */
    justify-content: center;
    align-items: center;
    /* border-radius: 20px; */

    padding-left: 5px;
    padding-right: 5px;
}

.Artist-buttons {
    /* position: relative; */
    display: grid;
    grid-template-columns: auto auto;

    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    overflow: hidden !important;
}

.Artist-list-item {
    /* text-shadow: 0px 0px 5px rgb(255, 255, 255); */
    text-align: center;

    cursor: none;

    /* set to column */
    flex-direction: column;
    justify-content: center;

    /* remove button styling */
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.27);
    border-radius: 10px;
    /* border: none; */

    margin: 5px;
    color: black;
}

.Artist-list-bid-info {
    text-align: center;

    cursor: none;

    /* set to column */
    flex-direction: column;
    justify-content: center;

    /* remove button styling */
    /* background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.27);
    border-radius: 10px; */
    /* border: none; */

    font-size: 10px;
    margin: 2px;
    color: black;

    opacity: 0;
}



.Artist-list-item:hover {
    background: rgba(255, 255, 255, 0.2);
}

.Artist-list-name {
    font-family: Duke;
    font-size: 16px;
    font-weight: bold;
    text-shadow: 0px 0px 10px rgb(255, 255, 255);
}

.Artist-list-title {
    font-family: Union;
    font-size: 12px;
    font-weight: normal;
    padding: 5px;
}

.Artist-list-timer {
    font-family: Union;
    font-size: 10px;
    font-weight: normal;
    padding: 5px;
}

.Artist-list-title button {
    color: black;
}

.Skip-intro {
    position: absolute;
    width: auto;
    /* min-width: 300px; */
    left: 0;
    z-index: 1000;

    background: rgb(255 255 255 / 10%);
    border: 1px solid black;

    text-shadow: 0px 0px 5px rgb(255, 255, 255);

    backdrop-filter: blur(5px);

    /* box-shadow: 0 0 20px 0 rgba(0,0,0,0.5); */

    cursor: none;

    font-family: Duke;
    font-size: 25px;
    /* font-weight: bold; */

    padding: 10px;
    margin: 10px;

    color: black;

    display: none;

    animation: glow 8s infinite;  
}
.Skip-intro-desktop {
    top: 50vh;
}

.Skip-intro-mobile {
    top: 50vh;
}


/* .Artist-list-item:hover {
    color: #00aaff;
} */

.hueAndPulse {
    animation: hueRotateAnim 4s infinite, pulseAnim 0.3s infinite ease-in-out, fadeInAnim 0.5s; 
}

.hueAnim {
    animation: hueRotateAnim 4s infinite, pulseAnim 3s infinite ease-in-out, fadeInAnim 0.5s;
}

.fadeIn {
    animation: fadeInAnim 0.5s ease-in-out;
    animation-fill-mode: forwards;
}

.fadeOut {
    animation: fadeOutAnim 0.5s ease-in-out;
    animation-fill-mode: forwards;
}

.fadeInSplash {
    animation: fadeInAnim 3s ease-in-out;
    animation-fill-mode: forwards;
}


.fadeOutSplash {
    animation: fadeOutAnim 3s ease-in-out;
    animation-fill-mode: forwards;
}


.showOverlayDesktop {
    top: var(--show-desktop-overlay-top) !important;
}    

.showOverlayDesktopHeight {
    height: calc( 100vh - var(--show-desktop-overlay-top) ) !important;
}

.showOverlayMobile {
    top: calc(100vh - var(--show-mobile-overlay-height)) !important;
}

.showOverlayMobileHeight {
    height: calc(var(--show-mobile-overlay-height)) !important;
}

.hideOverlayDesktop {
    top: var(--hide-desktop-overlay-top-wanderlust) !important;
}

.hideOverlayDesktopHeight {
    height: calc( 100vh - var(--hide-desktop-overlay-top-wanderlust)) !important;
}

.hideOverlayMobile {
    top: calc(100vh - var(--hide-mobile-overlay-height)) !important;
}

.hideOverlayMobileHeight {
    height: calc(var(--hide-mobile-overlay-height)) !important;
}

.WanderlustSplashScreen {
    position: absolute;
    z-index: 5;
    /* top: 50%;
    left: 50%; */
    width: 100vw;
    height: 100vh;
    /* transform: translate(-50%, -50%); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 1.0;
    pointer-events: none;
    /* background-image: url('../images/splashscreen.jpg'); */
}

.WanderlustSplashNormal {
    background-image: url('../images/splashscreen_wanderlust_yechelles.jpg');
    /* background-image: url('../images/splashscreen_nome_movie.jpg'); */
}

.WanderlustSplashDark {
    background-image: url('../images/splashscreen_wanderlust_yechelles.jpg');
    /* background-image: url('../images/splashscreen_nome_movie.jpg'); */
}


.Crossfade-overlay {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    opacity: 0;

    pointer-events: none;
}

.Wanderlust-unmute {
    top: 40vh;
    left: 40px;
    position: absolute;
    z-index: 10000000000;
    width: 100px;
    height: 100px;
    font-size: 40px;
    background: none;
    border: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Wanderlust-container {
    width: 100vw;
    height: 100vh;
    position: absolute;

    /* prevent scrolling */
    overflow: hidden !important;
    cursor: none;

    opacity: 0.0;

    touch-action: none;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Jumbotron {
    pointer-events: auto !important;
    position: absolute;
    z-index: 100000000;
}

.Crosshair {
    position: absolute;
    z-index: 1000000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    mix-blend-mode: color-burn;
    background-image: url('../images/crosshair_stroke.png');
    background-size: contain;
    /* opacity: 0.0; */
    /* animation: hueRotateAnim 4s infinite; */

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Crosshair-mobile {
    opacity: 0.0;
    width: 25px;
    height: 25px;
    mix-blend-mode: screen;
}

.Crosshair-desktop {
    opacity: 1.0;
    width: 35px;
    height: 35px;
}

.Wanderlust-joystick {
    position: absolute;
    top: 45vh;
    /* top: 55vh; */

    left: 8vw;
    z-index: 500;
    
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Wanderlust-jump-button {
    position: absolute;
    top: 45vh;
    /* top: 55vh; */

    right: 10vw;
    z-index: 500;
    width: 100px;
    height: 100px;
    /* border-radius: 50%;
    border: solid 2px rgb(48, 48, 48);
    background-color: #4d4d4d; */

    border: none;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Jump {
    background: url('../images/jump.png') no-repeat center;
    background-size: contain;
}

.Uturn {
    background: url('../images/uturn.png') no-repeat center;
    background-size: contain;
}



.OverlayDesktop {
    z-index: 9;
    width: 15vw;
    min-width: 300px;
    right: 0;
    top: var(--hide-desktop-overlay-top-wanderlust);
    height: calc( 100vh - var(--hide-desktop-overlay-top-wanderlust));

    position: absolute;
    /* overflow: scroll; */

    cursor: none;

    display: flex;
    flex-direction: column;

    transition: all 0.5s ease-in-out;

    row-gap: 40px;

    box-shadow: 0px 0px 30px rgb(0 0 0 / 30%);

    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
}

.OverlayMobile {
    z-index: 9;
    width: 100vw;
    top: calc(100vh - var(--hide-mobile-overlay-height));
    height: var(--hide-mobile-overlay-height);

    position: absolute;
    /* overflow: scroll; */

    display: flex;
    flex-direction: column;

    transition: all 0.5s ease-in-out;

    row-gap: 40px;

    box-shadow: 0px 0px 30px rgb(0 0 0 / 30%);

    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
}

.OverlayInnerDesktop {
    -webkit-mask-image: linear-gradient(to bottom, black 75%, transparent 95%);
    mask-image: linear-gradient(to bottom, black 75%, transparent 95%);  

    position: absolute;
    width: 100%;
    height: 100%;
    overflow: scroll;

    cursor: none;
}

.OverlayInnerMobile {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: scroll;
}


.WorkNavigatorDesktop {
    z-index: 10;

    width: 15vw;
    min-width: 300px;
    right: 0;
    top: var(--hide-desktop-overlay-top-wanderlust);

    cursor: none;

    /* border-top-right-radius: 15px;
    border-top-left-radius: 15px; */

    position: absolute;

    display: flex;
    flex-direction: column;

    /* flex-direction: row;
    justify-content: space-evenly; */

    transition: all 0.5s ease-in-out;

    padding-top: 5px;
    padding-bottom: 5px;

    backdrop-filter: blur(5px);

    /* border: 1px solid black; */

    box-shadow: 0px 0px 30px rgb(0 0 0 / 30%);

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.MessageBar {
    display: flex;
    flex-direction: row;
    flex-basis: 65%;
    justify-content: space-evenly;
    align-items: center;
    height: 28px;

    /* set spacing between elements to 20px */
    gap: 8px;
}

.MessageBox {
    border-radius: 5px;
    border: none;
    height: 100%;
    flex-basis: 90%;
    font-family: Duke;
}

.MessageBox::placeholder {
    color: #540051;
}

.SendMessageButton {
    /* max-width: 50px; */
    height: 28px;
    width: 28px;
    aspect-ratio: 1/1;
    background-image: url("../images/up_arrow_white.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    border: 1px solid white;
    background-color: transparent;
    /* background: none; */
}

/* .AvatarSwitchContainer {
    flex-basis: 30%;
} */

.AvatarController {
    z-index: 11;

    width: 100%;
    right: 0;

    cursor: none;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    transition: all 0.5s ease-in-out;

    padding-top: 15px;
    padding-bottom: 15px;
    
    -webkit-user-select: none; 
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.WorkNavigatorProgressBar {
    background-color:rgba(0, 255, 225, 0.2);
    /* background: hsl(0%,100%,70%); */
    width: 0%;
    height: 100%;
    position: absolute;

    animation: hueRotateAnim 8s infinite;

    /* center on parent div, which is absolute */
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
}


.NavigatorButtonRow {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.WorkNavigatorMobile {
    z-index: 10;

    width: 100vw;
    top: calc(100vh - var(--hide-mobile-overlay-height));

    position: absolute;

    touch-action: none;

    cursor: none;

    display: flex;

    flex-direction: column;

    /* flex-direction: row;
    justify-content: space-evenly; */

    transition: all 0.5s ease-in-out;

    padding-top: 5px;
    padding-bottom: 5px;

    backdrop-filter: blur(5px);

    /* border: 1px solid black; */

    box-shadow: 0px 0px 30px rgb(0 0 0 / 30%);

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.WorkNavigatorButton {
    border: none;
    background: none;

    cursor: none;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    /* background-color: #555;
    color: white; */
    font-size: 16px;
    padding: 12px 24px;
    border: none;
    /* cursor: pointer; */
    border-radius: 5px;  
    height: 100%;
    width: 100%;
}

.WorkNavigatorButtonMute {
    margin-top: 6px;
    width: 35px;
    height: 35px;
    position: relative;
    background-size: contain;
}

.SoundOff {
    background: url('../images/soundoff.png') no-repeat center;
    background-size: contain;
}

.SoundOn {
    background: url('../images/soundon.png') no-repeat center;
    background-size: contain;
}


.WorkNavigatorButtonArrow {
    margin-top: 6px;
    width: 35px;
    height: 35px;
    position: relative;
    background-size: contain;
}

.Arrow {
    background: url('../images/arrow.png') no-repeat center;
    background-size: contain;
}

.Cross {
    background: url('../images/cross.png') no-repeat center;
    background-size: contain;
}



.WorkNavigatorButtonPlay {
    margin-top: 6px;
    width: 40px;
    height: 40px;
    position: relative;
}

.PlayTour {
    background: url('../images/tour_play.png') no-repeat center;
    background-size: contain;
}

.PauseTour {
    background: url('../images/tour_pause.png') no-repeat center;
    background-size: contain;
}

.WorkNavigatorButtonPrev {
    /* margin-top: 5px; */
    width: 50px;
    height: 50px;
    position: relative;
    background: url('../images/tour_L.png') no-repeat center;
    background-size: contain;
    background-repeat: no-repeat;
}


.WorkNavigatorButtonNext {
    /* margin-top: 5px; */
    width: 50px;
    height: 50px;
    position: relative;
    background: url('../images/tour_R.png') no-repeat center;
    background-size: contain;
    background-repeat: no-repeat;
}

.OverlayPaneWanderlust {
    position: absolute;
    overflow: scroll;

    /* padding-top: 85px; */

    padding-top: 125px;

    padding-bottom: 100px;

    display: flex;
    row-gap: 20px;
    flex-direction: column;

    width: 100%;
}

.OverlayPaneItem-wanderlust {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    /* display: flex; */
    /* flex-direction: row;
    justify-content: space-evenly; */
}

.OverlayPaneItem-wanderlust a {
    color: pink;
}

.ArtThumbnailContainer {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.ArtThumbnailImg {
    width: auto;
    height: auto;

    max-height: 50%;
    max-width: 95%;
    object-fit: contain;
}

.ArtThumbnailImg img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
}

.ArtVideoThumbnail {
    width: auto;
    height: auto;

    max-height: 50%;
    max-width: 95%;
    object-fit: contain;
}

.ArtVideoThumbnail video {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
}

.SeparatorLine {
    width: 100%;
    height: 1px;
    background: rgb(59, 59, 59);
    opacity: 0.5;
}

.OverlayPaneArtHeaderWanderlust {
/* 
    display: grid;
    grid-template-columns: auto auto;  

    align-items: center;

    grid-template-columns: 50% 50%; */

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    align-items: center;

    width: 100%;

    /* opacity: 0; */
    
    /* set space between columns to 5px */
    grid-column-gap: 5px;
}

.WanderlustLogo {
    width: 100%;
    height: fit-content;
}

.ArtHeaderTitleNameWanderlust {
    font-family: Duke;
    font-weight: bold;
    font-size: 18px;
    width: 100%;
    height: auto;
    color: white;
    text-shadow: 0 0 5px black,  0 0 5px black,  0 0 5px black;
    display: flex; 
    /* text-align: center; */
    flex-direction: column;
    /* align-items: center; */
}

.BidHistoryHeader {
    font-family: Duke;
    font-weight: bold;
    font-size: 18px;
    max-width: 50%;
    margin-top: 10px;
    margin-bottom: 10px;
    height: auto;
    color: white;
    text-shadow: 0 0 5px black,  0 0 5px black,  0 0 5px black;
    display: flex; 
}

.BidDate {
    font-size: 14px;
    color: white;
    text-shadow: none;
    text-shadow: 0 0 3px black, 0 0 3px black;
}

.BidHistoryList {
    display: flex;
    flex-direction: column;
}

.OverlayPaneInfoDesktopWanderlust {
    /* opacity: 0; */

    color: white;
    text-shadow: 0 0 5px black, 0 0 5px black, 0 0 5px black;
    font-size: 16px;
}

.OverlayPaneInfoDesktopWanderlust a {
    color: rgb(248, 160, 226);
    text-shadow: 0 0 2px black, 0 0 2px black;
    font-size: 16px;
}

.OverlayPaneInfoMobileWanderlust {
    /* opacity: 1; */

    color: white;
    text-shadow: 0 0 5px black, 0 0 5px black, 0 0 5px black;
    font-size: 16px;
}

.OverlayPaneInfoMobileWanderlust a {
    color: rgb(248, 160, 226);
    text-shadow: 0 0 2px black, 0 0 2px black;
    font-size: 16px;
}

.OverlayCurrentBidInfo {
    font-family: Duke;
    font-weight: bold;
    color: white;
    max-width: 50%;
    text-shadow: 0 0 5px black,  0 0 5px black,  0 0 5px black;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.BidBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
}

.HighestBidInfo {
    font-family: Union;
    display: flex;
    /* justify-content: space-between; */
    flex-direction: row;
    font-weight: normal;
    font-size: 14px;
    color: white;
    text-shadow: none;
    text-shadow: 0 0 3px black, 0 0 3px black;
}

.ContractInfo {
    font-size: 16px;
    justify-content: end;
    color: white;
    text-shadow: 0 0 5px black,  0 0 5px black,  0 0 5px black;
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    font-weight: normal;
    width: 100%;
}

.Etherscan-link {
    display: flex;
    align-items: center;
}

.Etherscan-link img {
    width: 25px;
    height: 25px;
    filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.7));
}

.Opensea-link {
    display: flex;
    align-items: center;
}

.Opensea-link img {
    width: 25px;
    height: 25px;
    filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.7));
}


.BidAmt {
    font-family: Duke;
    font-weight: bold;
    color: white;
    text-shadow: 0 0 5px black,  0 0 5px black,  0 0 5px black;
    font-size: 12px;
}

.Bidder {
    font-family: Duke;
    font-weight: bold;
    color: white;
    text-shadow: 0 0 5px black,  0 0 5px black,  0 0 5px black;
    font-size: 12px;
}

.BidInput {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    height: 30px;
}

.BidInputBox {
    width: 50px;
    font-size: 14px;
    text-align: center;
}

.BidButton {
    width: 50px;
    font-family: Duke;
    font-weight: bold;
    font-size: 12px;
    color: black;
    /* text-shadow: 0 0 5px black,  0 0 5px black,  0 0 5px black; */
}

.AuctionEndTimer {
    font-family: Duke;
    font-weight: bold;
    color: white;
    text-shadow: 0 0 5px black,  0 0 5px black,  0 0 5px black;
    font-size: 12px;
    /* display: flex;
    flex-direction: column;
    row-gap: 20px; */
}

.TimerContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 15px;
}

.OvertimeTimerText {
    font-family: Union;
    color: white;
    text-shadow: 0 0 5px black,  0 0 5px black,  0 0 5px black;
    font-size: 12px;
}

.DarkYecheOvertimeTitle {
    color: black;
    font-weight: bold;
    display: inline;
    animation: glowText 5s infinite;
}   