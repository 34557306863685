@font-face {
    font-family: Union;
    font-weight: regular;
    src: url("./fonts/Union-Regular.otf") format("opentype");
}
  
@font-face {
    font-family: Union;
    font-weight: bold;
    src: url("./fonts/Union-Bold.otf") format("opentype");
}

@keyframes color-anim {
    0% { color: rgb(240, 24, 24); }
    33% { color: rgb(255, 85, 0); }
    66% { color: rgb(255, 0, 200); }
    100% { color: rgb(240, 24, 24); }
}

@keyframes highlight-anim {
    0% { text-shadow: 0px 0px 10px rgb(252, 176, 176), 0px 0px 20px rgb(252, 176, 176); }
    33% { text-shadow: 0px 0px 10px rgb(255, 182, 146), 0px 0px 20px rgb(255, 182, 146); }
    66% { text-shadow: 0px 0px 10px rgb(249, 154, 228), 0px 0px 20px rgb(249, 154, 228); }
    100% { text-shadow: 0px 0px 10px rgb(252, 176, 176), 0px 0px 20px rgb(252, 176, 176); }
}

.about-container {
    font-family: union;
    left: 50%;

    position: relative;
    top: 0%;
    transform: translate(-50%, 0%);
    margin-bottom: 26vh;
    padding-top: 20vh;
    justify-content: flex-start;
}

.about-statement {
    position: relative;
    line-height: 1.5;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    padding: 45px;
    margin: 20px;
    width: 70%;
    margin: 0 auto;
    text-shadow: 0px 0px 5px #ffffff, 0px 0px 20px #ffffff;
    backdrop-filter: blur(10px);
}

.about-statement a {
    color: rgb(180 0 163);
    animation: color-anim 3s infinite, highlight-anim 5s infinite;
    text-decoration: none;
}

/* desktop */
@media only screen and (min-width: 480px) {
    .about-statement {
        font-size: 1.2em;
    }
}

/* mobile */
@media only screen and (max-width: 480px) {
    .about-statement {
        font-size: 0.8em;
    }
}