@font-face {
    font-family: 'Duke';
    src: local('Duke'), url(./fonts/DUKECONN.TTF) format('truetype');
}

@font-face {
    font-family: Union;
    font-weight: regular;
    src: url("./fonts/Union-Regular.otf") format("opentype");
}

@keyframes oreo-color-anim {
    0% { color: rgb(0, 0, 0); }
    50% { color: rgb(255, 255, 255); }
    100% { color: rgb(0, 0, 0); }
}

@keyframes oreo-highlight-anim {
    0% { text-shadow: 0px 0px 5px rgb(255, 255, 255), 0px 0px 5px rgb(255, 255, 255), 0px 0px 5px rgb(255, 255, 255); }
    50% { text-shadow: 0px 0px 5px rgb(0, 0, 0), 0px 0px 5px rgb(0, 0, 0), 0px 0px 5px rgb(0, 0, 0); }
    100% { text-shadow: 0px 0px 5px rgb(255, 255, 255), 0px 0px 5px rgb(255, 255, 255), 0px 0px 5px rgb(255, 255, 255); }
}

@keyframes glow {
    0% { box-shadow: 0px 0px 20px rgba(240, 24, 24, 0.7), 0px 0px 10px rgba(240, 24, 24, 0.5); }
    20% { box-shadow: 0px 0px 20px rgba(255, 0, 200, 0.7), 0px 0px 10px rgba(255, 0, 200, 0.5); }
    40% { box-shadow: 0px 0px 20px rgba(240, 24, 24, 0.7), 0px 0px 10px rgba(240, 24, 24, 0.5); }
    
    60% { box-shadow: 0px 0px 20px rgba(15, 231, 231, 0.7), 0px 0px 10px rgba(15, 231, 231, 0.5); }
    80% { box-shadow: 0px 0px 20px rgba(0, 255, 55, 0.7), 0px 0px 10px rgba(0, 255, 55, 0.5); }
    100% { box-shadow: 0px 0px 20px rgba(240, 24, 24, 0.7), 0px 0px 10px rgba(240, 24, 24, 0.5); }
}  

.bg-whistlegraph {
    z-index: -1;
    background-image: url("./images/2023_bg_1.jpg") !important;
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0px;
    top: 0px;
    padding: 1px;
    background-size: cover;
    background-position: 50% 13%;
    background-repeat: no-repeat;
}

.Etherscan-link img {
    width: 25px;
    height: 25px;
    filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.7));
}

.Whistlegraph-fullres-link a {
    color: black;
    text-decoration: none;
    text-shadow: 0px 0px 5px #ffffff, 0px 0px 20px #ffffff;
}   

.Whistlegraph-title {
    font-family: Duke;
    font-weight: bold;
    color: white;
    text-shadow: 0 0 5px black,  0 0 5px black,  0 0 5px black;
}

.Whistlegraph-header {
    display: grid;
    flex-direction: row;
    width: 80%;
    /* make first column take up 80% of the space */
    grid-template-columns: 90% 10%;
}

.Whistlegraph-info-desktop {
    width: 33vw;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;

    row-gap: 40px;
    left: 0;
    
    position: absolute;

    box-shadow: 0px 0px 30px rgb(0 0 0 / 30%);

    /*make items vertical*/
    flex-direction: column;

    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
}

.Whistlegraph-container-mobile {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    row-gap: 20px;

    position: absolute;
    /* top: 18vh; */
    left: 0;
    right: 0;

    margin-left: auto;
    margin-right: auto;

    flex-direction: column;
}

.Whistlegraph-info-mobile {
    width: 100vw;
    height: 100vh;

    display: flex;
    /* justify-content: center; */
    align-items: center;

    padding-top: 5vh;
    padding-bottom: 25vh;

    row-gap: 20px;
    left: 0;
    
    position: relative;

    
    /*make items vertical*/
    flex-direction: column;
    
    box-shadow: 0px 0px 30px rgb(0 0 0 / 30%);
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
}


.Whistlegraph-info-item {
    /* align left */
    text-align: left;
    width: 70%;

    box-shadow: 0px 0px 30px rgb(0 0 0 / 30%);
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);

    border-radius: 15px;
    padding: 30px;
}

.Whistlegraph-preview-mobile {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.Whistlegraph-preview-mobile img {
    width: 90%;
    object-fit: cover;  
    filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.7));
}

.Whistlegraph-preview-desktop {
    height: 85vh;
    width: 66.66vw;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15vh;
}

.Whistlegraph-preview-desktop img {
    height: 92%;
    object-fit: contain;  
    max-width: 60vw;
    filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.7));
}


.Whistlegraph-preview {
    display: flex;
    flex-direction: column;
}

.Whistlegraph-preview img {
    margin: 0 auto;
    /* cursor: zoom-in; */
    width: 70%;
}

.Whistlegraph-lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;

    transition: opacity 1s;
  
    opacity: 0.0;
    pointer-events: none;
}

.Whistlegraph-lightbox.visible {
    opacity: 1.0;
    pointer-events: auto;
} 

.Countdown {
    font-size: large;
    font-family: Duke;
    text-shadow: 0px 0px 5px #ffffff, 0px 0px 20px #ffffff, 0px 0px 20px #ffffff;
    animation: oreo-color-anim 4s infinite, oreo-highlight-anim 4s infinite;
}

.WhistlegraphText {
    text-align: left;
    line-height: 2.0;
    border-radius: 15px;
    text-shadow: 0px 0px 5px #ffffff, 0px 0px 20px #ffffff;
}

.WhistlegraphText a {
    color: black;
    text-decoration: underline;
    text-decoration-color: black;
}

.Address-pill-whistlegraph {
    color: black;
    z-index: 2;
    border: 1px solid #3f3f3f;
    backdrop-filter: blur(5px);
    background-color: rgba(255,255,255,0.2);
    box-shadow: 0px 0px 30px #55ffe871;
    position: fixed;
    border-radius: 5px;
    left: 3vw;
    top: 18vh;
    padding: 10px;
    border-radius: 10px;
}

.Connect-button-whistlegraph {
    width: max-content;
    left: 3vw;
    top: 18vh;

    color: black;

    font-family: Union;
  
    z-index: 2;
    border: 1px solid #3f3f3f;
    backdrop-filter: blur(5px);
    background-color: rgba(255,255,255,0.2);
    position: fixed;
    border-radius: 5px;
    padding: 10px;
    border-radius: 10px;
  
    text-shadow: 0px 0px 5px #ffffff, 0px 0px 10px #ffffff, 0px 0px 10px #ffffff;
  
    animation: glow 8s infinite;  
}

.Mint-button-inactive-whistlegraph {
    color: #3a0348;
  
    border: 1px solid rgb(103, 103, 103);
    width: fit-content;
    backdrop-filter: blur(5px);
  
    font-size: 1.2em;
  
    box-shadow: 0px 0px 40px #cfcfcff5;
  
    background-color: rgba(255, 255, 255, 0.4);
   
    border-radius: 5px;
    padding: 10px;
    border-radius: 10px;  
}
  
.Mint-button-active-whistlegraph {
    color: #ffffff;
  
    border: 1px solid rgb(0, 0, 0);
    width: fit-content;
    backdrop-filter: blur(5px);
  
    font-size: 1.2em;
  
    box-shadow: 0px 0px 20px #e60c79f5, 0px 0px 30px #e60c79f5;
   
    background-color: rgb(255 122 238 / 40%);
   
    border-radius: 5px;
    padding: 10px;
    border-radius: 10px;
}

/* mobile */
@media only screen and (max-width: 1000px) {
    .Connect-button-whistlegraph {
        font-size: 13px;
    }

    .Countdown {
        font-size: 1.0em;
    }

    .WhistlegraphText {
        font-size: 0.8em;
    }

    .Whistlegraph-fullres-link a {
        font-size: 0.8em;
    }

    .Address-pill-whistlegraph {
        font-size: 13px;
    }

    .WhistlegraphContainer {
        width: 90%;
        flex-direction: vertical;
    }

    .Whistlegraph-lightbox img {
        max-width: 90%;
    }    


}

/* desktop */
@media only screen and (min-width: 1000px) {
    .Whistlegraph-title {
        font-size: 1.3em;
    }

    .Connect-button-whistlegraph {
        font-size: 18px;
    }

    .Countdown {
        font-size: 1.4em;
    }

    .WhistlegraphText {
        font-size: 1.2em;
    }

    .Whistlegraph-fullres-link a {
        font-size: 1.2em;
    }

    .Address-pill-whistlegraph {
        font-size: 18px;
    }

    .WhistlegraphContainer {
        width: 100%;
        flex-direction: horizontal;
        /* reverse order of items */
        flex-direction: row-reverse;
    }

    .Whistlegraph-lightbox img {
        max-height: 90%;
    }    
}

