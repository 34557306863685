
@font-face {
    font-family: 'Duke';
    src: local('Duke'), url(./fonts/DUKECONN.TTF) format('truetype');
}

.thumbnail-caption {
    text-align: center;
    text-decoration: none;
    font-family: 'Duke', serif;
    text-shadow: 0px 0px 10px #ffffff, 0px 0px 10px #ffffff, 0px 0px 10px #ffffff;
    animation: oreo-color-anim 4s infinite, oreo-highlight-anim 4s infinite;
}

.Panel-item-active {
    color: pink;
    animation: color-anim 3s infinite, highlight-anim 5s infinite;

    text-decoration: none;
}

.Panel-item-inactive {
    color: black;
    text-decoration: none;
}

.Panel {
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.6);
}

@keyframes color-anim {
    0% { color: rgb(240, 24, 24); }
    33% { color: rgb(255, 85, 0); }
    66% { color: rgb(255, 0, 200); }
    100% { color: rgb(240, 24, 24); }
}

@keyframes highlight-anim {
    0% { text-shadow: 0px 0px 10px rgb(252, 176, 176), 0px 0px 10px rgb(252, 176, 176), 0px 0px 10px rgb(252, 176, 176); }
    33% { text-shadow: 0px 0px 10px rgb(255, 182, 146), 0px 0px 10px rgb(255, 182, 146), 0px 0px 10px rgb(255, 182, 146); }
    66% { text-shadow: 0px 0px 10px rgb(249, 154, 228), 0px 0px 10px rgb(249, 154, 228), 0px 0px 10px rgb(249, 154, 228); }
    100% { text-shadow: 0px 0px 10px rgb(252, 176, 176), 0px 0px 10px rgb(252, 176, 176), 0px 0px 10px rgb(252, 176, 176); }
}

@keyframes oreo-color-anim {
    0% { color: rgb(0, 0, 0); }
    50% { color: rgb(255, 255, 255); }
    100% { color: rgb(0, 0, 0); }
}

@keyframes oreo-highlight-anim {
    0% { text-shadow: 0px 0px 5px rgb(255, 255, 255), 0px 0px 5px rgb(255, 255, 255), 0px 0px 5px rgb(255, 255, 255); }
    50% { text-shadow: 0px 0px 5px rgb(0, 0, 0), 0px 0px 5px rgb(0, 0, 0), 0px 0px 5px rgb(0, 0, 0); }
    100% { text-shadow: 0px 0px 5px rgb(255, 255, 255), 0px 0px 5px rgb(255, 255, 255), 0px 0px 5px rgb(255, 255, 255); }
}

/* mobile */
@media only screen and (max-width: 480px) {

    .Panel-img {
        width: 100px;

        margin: auto;
        display: block;        
    }

    .Panel-items {
        width: 80%;

        display: grid;

        position: relative;

        transform: translateX(-50%);
        left: 50%;

        grid-auto-flow: column;

        grid-column-gap: 2vw;
        grid-row-gap: 2vh;

        align-items: center;
        justify-items: center;
    }

    .thumbnail-caption {
        font-size: 0.7em;
    }
        
    .Panel {
        padding-top: 10px;
        padding-bottom: 10px;

        width: 100%;

        bottom: 0%;

        position: fixed;

        background-color: #2020203b; 
        backdrop-filter: blur(5px); 

        box-shadow: 0px 0px 15px #3b3b3b;

        /* height: 15vh; */

        z-index: 1;
    }
}

/* desktop */
@media only screen and (min-width: 480px) {

    .Panel-img {
        width: 100%;
        height: auto;      
    }
        
    .thumbnail-caption {
        font-size: 1em;
    }

    .Panel-items {
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: 2vw;
        grid-row-gap: 2vh;

        position: relative;

        align-items: center;
        justify-items: center;

        -webkit-touch-callout: none; 
        -webkit-user-select: none;
         -khtml-user-select: none; 
           -moz-user-select: none;
            -ms-user-select: none; 
                user-select: none;     
    }

    .Panel {

        padding: 15px;

        width: 7%;

        top: 50%;
        transform: translateY(-50%);

        height: auto;

        right: 0;

        position: fixed;

        background-color: #2020203b; 
        backdrop-filter: blur(5px); 

        border: 1px solid #3f3f3f;

        /*border-radius: 5px 0px 0px 5px;*/
        z-index: 1;
    }
}