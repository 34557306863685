
.Leche .LecheContainer {
    width: 100vw;
    height: 100vh;
    position: absolute;

    overflow: hidden !important;

    touch-action: none;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
