@font-face {
    font-family: Union;
    font-weight: regular;
    src: url("./fonts/Union-Regular.otf") format("opentype");
}
  
@font-face {
    font-family: Union;
    font-weight: bold;
    src: url("./fonts/Union-Bold.otf") format("opentype");
}

.thesis-container {
    font-family: union;
    left: 50%;

    position: relative;
    top: 0%;
    transform: translate(-50%, 0%);
    margin-bottom: 26vh;
    padding-top: 20vh;
    justify-content: flex-start;
}

.thesis-statement {
    position: relative;
    line-height: 1.5;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    padding: 45px;
    margin: 20px;
    width: 70%;
    margin: 0 auto;
    text-shadow: 0px 0px 5px #ffffff, 0px 0px 20px #ffffff;
    backdrop-filter: blur(10px);
}

/* desktop */
@media only screen and (min-width: 480px) {
    .thesis-statement {
        font-size: 1.2em;
    }
}

/* mobile */
@media only screen and (max-width: 480px) {
    .thesis-statement {
        font-size: 0.8em;
    }
}