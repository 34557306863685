@font-face {
    font-family: 'Pantasia';
    src: url(./fonts/Pantasia-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Duke';
    src: local('Duke'), url(./fonts/DUKECONN.TTF) format('truetype');
}

@keyframes oreo-color-anim {
    0% { color: rgb(0, 0, 0); }
    50% { color: rgb(255, 255, 255); }
    100% { color: rgb(0, 0, 0); }
}

@keyframes oreo-color-anim-bar {
    0% { background: rgb(0, 0, 0); }
    50% { background: rgb(255, 255, 255); }
    100% { background: rgb(0, 0, 0); }
}

.Nav-inverted {
    width: 100%;
    height: 15vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 12;
    isolation: isolate;

    mix-blend-mode: multiply;

    pointer-events: none;
}

.Nav {
    width: 100%;
    height: 4vh;
    background-color: #2020203b;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;

    backdrop-filter: blur(5px); 

    box-shadow: 0px 0px 15px #3b3b3b;
}

.Nav-spacer {
    flex: 1;
}

.Nav-items {
    height: 7%;
    position: absolute;
    
    gap: 3vw;

    left: 50%;

    z-index: 1;

    display: flex;
    justify-content: flex-end;
    align-items: center;

    margin: 0 auto;

    -webkit-touch-callout: none; 
    -webkit-user-select: none;
     -khtml-user-select: none; 
       -moz-user-select: none;
        -ms-user-select: none; 
            user-select: none; 
}

.Nav-items a {
    display: flex;
    align-items: center;
    text-align: center;
    
    /* font-family: Pantasia; */
    font-family: Duke;
    font-weight: bold;  
    line-height: 1.2;

    text-decoration: none;

    /* text-shadow: 0px 0px 10px #ffffff, 0px 0px 20px #ffffff; */

    animation: oreo-color-anim 4s infinite, oreo-highlight-anim 4s infinite;
}

.Nav-items img {
    filter: drop-shadow(0px 0px 10px rgb(255 255 255 / 0.8));
}

@-webkit-keyframes hue {
    0%, 100%  { -webkit-filter: brightness(0.5) hue-rotate(0deg) drop-shadow(0px 0px 10px rgba(229, 127, 228, 0.8)); }
    50% { -webkit-filter: brightness(0.5) hue-rotate(180deg) drop-shadow(0px 0px 10px rgba(152, 224, 152, 0.8)); }
}

@keyframes hue {
    0%, 100%  { filter: brightness(0.5) hue-rotate(0deg) drop-shadow(0px 0px 10px rgba(229, 127, 228, 0.8)); }
    50% { filter: brightness(0.5) hue-rotate(180deg) drop-shadow(0px 0px 10px rgba(152, 224, 152, 0.8)); }
}

.Home-button {
    position: absolute;
    top: 130%;
    left: 10px;
}
    
.Home-button img {
    width: 100%;
    /* display: none; */
    -webkit-animation: hue 15s infinite;
    animation: hue 15s infinite;
}

.active-link {
    color: pink;
    animation: color-anim 3s infinite, highlight-anim 5s infinite;
    
    text-decoration: none;
    overflow: visible;
    -webkit-transform: translateZ(0);
}

.inactive-link {
    
    color: #000000;

    text-decoration: none;
    overflow: visible;
}

.active-link a {
    text-decoration: none;
}

.inactive-link a {
    text-decoration: none;
}

@keyframes color-anim {
    0% { color: rgb(240, 24, 24); }
    33% { color: rgb(255, 85, 0); }
    66% { color: rgb(255, 0, 200); }
    100% { color: rgb(240, 24, 24); }
}

@keyframes highlight-anim {
    0% { text-shadow: 0px 0px 10px rgb(252, 176, 176), 0px 0px 20px rgb(252, 176, 176); }
    33% { text-shadow: 0px 0px 10px rgb(255, 182, 146), 0px 0px 20px rgb(255, 182, 146); }
    66% { text-shadow: 0px 0px 10px rgb(249, 154, 228), 0px 0px 20px rgb(249, 154, 228); }
    100% { text-shadow: 0px 0px 10px rgb(252, 176, 176), 0px 0px 20px rgb(252, 176, 176); }
}

/* mobile */
@media only screen and (max-width: 480px) {
    .Home-button {
        width: 90px;
    }

    .Nav-items {
        width: 95%;

        top: 50%;
        transform: translate(-50%, -50%);    
    }
    
    .Nav-items a {
        font-size: 1.0em;
    }

    .Nav-items img {
        width: 60%;
    }    
}

/* desktop */
@media only screen and (min-width: 480px) {
    .Home-button {
        width: 110px;
    }
    
    .Nav-items {
        width: 95%;

        top: 50%;
        transform: translate(-50%, -50%);    
    }

    .Nav-items a {
        font-size: 1.3em;
    }

    .Nav-items img {
        width: 80%;
    }    
}