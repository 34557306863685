@font-face {
    font-family: 'Duke';
    src: local('Duke'), url(./fonts/DUKECONN.TTF) format('truetype');
}

@font-face {
    font-family: Union;
    font-weight: regular;
    src: url("./fonts/Union-Regular.otf") format("opentype");
}
  
@font-face {
    font-family: Union;
    font-weight: bold;
    src: url("./fonts/Union-Bold.otf") format("opentype");
}

@-webkit-keyframes hue {
    0%, 100%  { -webkit-filter: hue-rotate(0deg); }
    50% { -webkit-filter: hue-rotate(180deg); }
}

@keyframes oreo-color-anim {
    0% { color: rgb(0, 0, 0); }
    50% { color: rgb(255, 255, 255); }
    100% { color: rgb(0, 0, 0); }
}

@keyframes oreo-highlight-anim {
    0% { text-shadow: 0px 0px 5px rgb(255, 255, 255), 0px 0px 5px rgb(255, 255, 255), 0px 0px 5px rgb(255, 255, 255); }
    50% { text-shadow: 0px 0px 5px rgb(0, 0, 0), 0px 0px 5px rgb(0, 0, 0), 0px 0px 5px rgb(0, 0, 0); }
    100% { text-shadow: 0px 0px 5px rgb(255, 255, 255), 0px 0px 5px rgb(255, 255, 255), 0px 0px 5px rgb(255, 255, 255); }
}

@keyframes color-anim {
    0% { color: rgb(240, 24, 24); }
    33% { color: rgb(255, 85, 0); }
    66% { color: rgb(255, 0, 200); }
    100% { color: rgb(240, 24, 24); }
}

@keyframes halo-anim {
    0% { filter: drop-shadow(0px 0px 5px rgb(240, 24, 24)) drop-shadow(0px 0px 5px rgb(240, 24, 24)); }
    33% { filter: drop-shadow(0px 0px 5px rgb(255, 85, 0)) drop-shadow(0px 0px 5px rgb(255, 85, 0)); }
    66% { filter: drop-shadow(0px 0px 5px rgb(255, 0, 200)) drop-shadow(0px 0px 5px rgb(255, 0, 200)); }
    100% { filter: drop-shadow(0px 0px 5px rgb(240, 24, 24)) drop-shadow(0px 0px 5px rgb(240, 24, 24)); }
}

@keyframes halo-anim-mint {
    0% { box-shadow: 0px 0px 20px rgb(252, 176, 176), 0px 0px 10px rgb(252, 176, 176); }
    33% { box-shadow: 0px 0px 20px rgb(255, 182, 146), 0px 0px 10px rgb(255, 182, 146); }
    66% { box-shadow: 0px 0px 20px rgb(249, 154, 228), 0px 0px 10px rgb(249, 154, 228); }
    100% { box-shadow: 0px 0px 20px rgb(252, 176, 176), 0px 0px 10px rgb(252, 176, 176); }
}

@keyframes hue {
    0%, 100%  { filter: hue-rotate(0deg); }
    50% { filter: hue-rotate(180deg); }
}

@keyframes glow {
    0% { box-shadow: 0px 0px 20px rgba(240, 24, 24, 0.7), 0px 0px 10px rgba(240, 24, 24, 0.5); }
    20% { box-shadow: 0px 0px 20px rgba(255, 0, 200, 0.7), 0px 0px 10px rgba(255, 0, 200, 0.5); }
    40% { box-shadow: 0px 0px 20px rgba(240, 24, 24, 0.7), 0px 0px 10px rgba(240, 24, 24, 0.5); }
    
    60% { box-shadow: 0px 0px 20px rgba(15, 231, 231, 0.7), 0px 0px 10px rgba(15, 231, 231, 0.5); }
    80% { box-shadow: 0px 0px 20px rgba(0, 255, 55, 0.7), 0px 0px 10px rgba(0, 255, 55, 0.5); }
    100% { box-shadow: 0px 0px 20px rgba(240, 24, 24, 0.7), 0px 0px 10px rgba(240, 24, 24, 0.5); }
}  

@keyframes glow-popup {
    from {
      box-shadow: 0px 0px 50px #0000009d, 0px 0px 30px #0000009d;
  
    }
    to {
      box-shadow: 0px 0px 50px #0ce6b7f5, 0px 0px 30px #0ce6b7f5;
    }
}  

@-webkit-keyframes wiggleL {
    0%, 100% { padding-right: 50px;}
    50% { padding-right: 60px;}
}

@keyframes wiggleL {
    0%, 100% { padding-right: 50px;}
    50% { padding-right: 60px;}
}

@-webkit-keyframes wiggleR {
    0%, 100% { padding-left: 50px;}
    50% { padding-left: 60px;}
}

@keyframes wiggleR {
    0%, 100% { padding-left: 50px;}
    50% { padding-left: 60px;}
}

@keyframes fadeOut {
    0% { opacity: 1; visibility: visible; }
    100% { opacity: 0; visibility: hidden;}
}

@-webkit-keyframes fadeOut {
    0% { opacity: 1; visibility: visible; }
    100% { opacity: 0; visibility: hidden; }
}

@keyframes fadeIn {
    0% { opacity: 0; visibility: hidden; }
    100% { opacity: 1; visibility: visible;}
}

@-webkit-keyframes fadeIn {
    0% { opacity: 0; visibility: hidden; }
    100% { opacity: 1; visibility: visible; }
}

button {
    cursor: pointer;
}

.Creation-links {
    width: 90%;
    display: flex;
    padding: 5px;
    flex-direction: column;
    text-align: center;
    row-gap: 10px;
}

.Creation-lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;

    transition: opacity 1s;
  
    opacity: 0.0;
    pointer-events: none;
}

.Creation-lightbox.visible {
    opacity: 1.0;
    pointer-events: auto;
} 

.Creation-lightbox img {
    max-height: 90%;
}
  

.Creation-other-links {
    text-shadow: none;
    /* make a flex box with two columns and spacing between them */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
}

.Creation-links a {
    color: #000;
    text-decoration: none;
    text-shadow: #ffffff 0px 0px 5px, #ffffff 0px 0px 5px, #ffffff 0px 0px 10px;
}

.Creation-links a:hover {
    color: #000;
    text-decoration: underline;
    text-shadow: #ffffff 0px 0px 5px, #ffffff 0px 0px 5px, #ffffff 0px 0px 10px;
}

.Enter-wallet-container {
    width: 20%;
    top: 50%;
    left: 5%;
    transform: translate(-50, -50%);
    /*center horizontally*/ 
    position: absolute;
    text-align: left;   
}

.Enter-wallet-container input {
    width: 100%;
}

.Portal-error {
    font-family: Duke;
    font-size: 5vw;
    width: 80%;
    text-align: center;
    /* center vertically */
    position: absolute;
    top: 50%;

    /* center horizontally */
    left: 50%;
    margin-left: -40%; /* Half of the element's width */
}

.Creation-babies-pane {
    width: 80%;
}

.Preview-container {
    position: relative;
    width: 100%;
}


@media (orientation: landscape) {
    .Preview-carousel {
        position: relative;
        width: 11vw;
        margin-left: -5.5vw; /* Half of the element's width */
        left: 50%;
        overflow: hidden;
    }    
}
  
@media (orientation: portrait) {
    .Preview-carousel {
        position: relative;
        width: 24vw;
        margin-left: -12vw; /* Half of the element's width */
        left: 50%;
        overflow: hidden;
    }    
}
  

.Preview-hover {
    position: absolute;
    z-index: 2;
    /* center horizontally */
    
    width: 25vw;
    left: 50%;
    margin-left: -12.5vw; /* Half of the element's width */

    pointer-events: none;

    overflow: hidden;
    
    opacity: 0.0;

    transition: opacity 1s;
}

.Preview-hover img {
    /* add a purple glow*/
    box-shadow: 0 0 20px 5px rgba(255, 0, 200, 0.5);
    overflow: hidden;
}


.Preview-hover.visible {
    opacity: 1;
}

.Preview-minted-by {}

.Creation-header {
    font-family: Duke;
    font-weight: bold;
    /*make text white with black outline*/
    color: white;
    /*add black text shadow*/
    text-shadow: 0 0 5px black,  0 0 5px black,  0 0 5px black;
}

audio {
    width: 80%;
    position: relative;
    left: 50%;
    margin-left: -40%;
    height: 40px;
}

.Audio-player {
    position: absolute;
    border: 1px solid black;
    border-radius: 25px;
    left: 3vw;
    bottom: 10vh;
    padding: 10px;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: grab;
    backdrop-filter: blur(5px);
    box-shadow: 0 0 20px 5px rgba(255, 255, 255, 0.5);
    text-shadow: #ffffff 0px 0px 5px, #ffffff 0px 0px 10px;
    z-index: 100;
}

.Audio-player-button {
    background: transparent;
    padding: 0px;
    border: none;
    margin: 0px;
}

.Audio-player img {
    position: relative;
    /* add white drop shadow to image */
    filter: drop-shadow(0 0 5px white);
}
  
.Play-audio-button {
    border: none;
    /* give transparent background with png */
    background-color: rgba(0,0,0,0.0);
}

.Play-audio-button img {
    width: 50px;
}

.Mint-button-grid {
    display: flex;
    padding: 10px;
    /* grid-template-columns: repeat(2, 1fr); */
    width: 80%;

    /*space items out evenly */
    justify-content: space-evenly;

    /*center the div horizontally on the parent div */
    /* justify-content: center; */

    /* make it become to 2 rows when the screen is small */
    /* flex-wrap: wrap; */

    position: relative;
    left: 0%;
}

.Mint-button {
    border: 1px solid rgb(20, 20, 20);
    border-radius: 5px;
    padding: 2.5px;
    text-align: center;
    width: max-content;
    /* backdrop-filter: blur(5px); */
    background-color: rgba(255,255,255,0.2);
    animation: glow 8s infinite;  
    /* force text onto one line */
    white-space: nowrap;
}

.Connect-button {
    width: max-content;
    left: 3vw;
    top: 18vh;
    /* blur background */

    color: black;

    font-family: Union;
  
    z-index: 2;
    border: 1px solid #3f3f3f;
    backdrop-filter: blur(5px);
    background-color: rgba(255,255,255,0.2);
    position: fixed;
    border-radius: 5px;
    padding: 10px;
    border-radius: 10px;
  
    text-shadow: 0px 0px 5px #ffffff, 0px 0px 10px #ffffff, 0px 0px 10px #ffffff;
  
    animation: glow 8s infinite;  

    /* transition: opacity 2s;
    opacity: 0.0; */
    /* display: none; */
}

.Giveaway-button {
    width: max-content;
    left: 3vw;
    top: 25vh;

    color: rgb(236, 195, 12);

    font-family: Union;
    font-weight: bold;
  
    z-index: 2;
    border: 1px solid #3f3f3f;
    backdrop-filter: blur(5px);
    background-color: rgba(255,255,255,0.2);
    position: fixed;
    border-radius: 5px;
    padding: 10px;
    border-radius: 10px;
  
    text-shadow: 0px 0px 5px #000, 0px 0px 10px #000, 0px 0px 10px #000;
  
    animation: glow 8s infinite, oreo-color-anim 4s infinite, oreo-highlight-anim 4s infinite;

    text-decoration: none;
}

/* .Connect-button.visible {
    opacity: 1.0;
} */

.Address-pill {
    color: black;
    z-index: 2;
    border: 1px solid #3f3f3f;
    backdrop-filter: blur(5px);
    background-color: rgba(255,255,255,0.2);
    box-shadow: 0px 0px 30px #55ffe871;
    position: fixed;
    border-radius: 5px;
    left: 3vw;
    top: 18vh;
    padding: 10px;
    border-radius: 10px;
  }

.Carousel-img {
    width: 100%;
    height: auto;
    cursor: grab;
}

.Pan-arrows {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    cursor: grab;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 10px;
    z-index: 1;

    -webkit-touch-callout: none; 
    -webkit-user-select: none;
     -khtml-user-select: none; 
       -moz-user-select: none;
        -ms-user-select: none; 
            user-select: none; 
}

.Pan-ball img {
    width: 55%;

    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.Pan-arrow-up img {
    width: 60%;

    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);

    filter: drop-shadow(0px 0px 10px #ffffff);
    /* animation: halo-anim 3s infinite alternate;   */

    /* make it so this can't be selected */

}

.Pan-arrow-right img {
    width: 60%;

    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate(-50%, -50%) rotate(90deg);

    filter: drop-shadow(0px 0px 10px #ffffff);
    /* animation: halo-anim 3s infinite alternate;   */
}

.Pan-arrow-down img {
    width: 60%;

    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(180deg);

    filter: drop-shadow(0px 0px 10px #ffffff);
    /* animation: halo-anim 3s infinite alternate; */
}

.Pan-arrow-left img {
    width: 60%;

    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%) rotate(270deg);

    filter: drop-shadow(0px 0px 10px #ffffff);
    /* animation: halo-anim 3s infinite alternate; */
}

.RecordMintButton {
    position: relative;
    /* top: 80vh;
    left: 10vw; */
    width: fit-content;
    text-align: center;
    height: fit-content;
    padding: 10px;
    margin-top: 20px;
    text-shadow: #ffffff 0px 0px 20px;
    box-shadow: #ffffff 0px 0px 20px;
    border-radius: 15px;
    backdrop-filter: blur(5px);
    background: transparent;
    border: 1px solid black;
    cursor: pointer;
    z-index: 1;
}

.Split-container {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow-y: hidden;
}

.OneOfOneOneOne-container {
    width: 100%;
    height: 100%;
    position: absolute;
}

.Split-separator {
    height: 100%;
    right: 33.3vw;
    cursor: grab !important;
    position: absolute;
    background-color: rgb(255, 0, 247);
    mix-blend-mode: multiply;
    animation: hue 15s infinite;
    -webkit-animation: hue 15s infinite;
    z-index: 9;
}

.Drag-arrow-L {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    padding-right: 10vw;

    color: rgb(255, 0, 247);

    font-size: 30px;
    font-weight: bold;
    
    mix-blend-mode: multiply;
    animation: hue 15s infinite, wiggleL 1s infinite;
    -webkit-animation: hue 15s infinite, wiggleL 1s infinite;
    z-index: 9;

    -webkit-touch-callout: none; 
    -webkit-user-select: none;
     -khtml-user-select: none; 
       -moz-user-select: none;
        -ms-user-select: none; 
            user-select: none; 
}

.Drag-arrow-R {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    padding-left: 10vw;

    color: rgb(255, 0, 247);

    font-size: 30px;
    font-weight: bold;

    mix-blend-mode: multiply;
    animation: hue 15s infinite, wiggleR 1s infinite;
    -webkit-animation: hue 15s infinite, wiggleR 1s infinite;
    z-index: 9;

    -webkit-touch-callout: none; 
    -webkit-user-select: none;
     -khtml-user-select: none; 
       -moz-user-select: none;
        -ms-user-select: none; 
            user-select: none; 
}

.OneOfOneOneOne-info {
    top: 0%;
    /* left: 50%; */
    right: 0%;
    /* transform: translate(-50%, 0%); */
    display: flex;

    z-index: 6;
    
    width: 33.3vw;

    /* blur background */
    backdrop-filter: blur(5px);

    gap: 15px;
    height: 100%;
    
    overflow-x: hidden;
    overflow-y: auto;

    padding-bottom: 0px;

    align-items: center;
    flex-direction: column;
    position: absolute;
}

.Mint-panel {
    position: absolute;
    right: 0%;
    width: 33.3vw;
    height: auto;
    max-height: 40vh;
    padding-bottom: 15px;
    padding-top: 15px;

    bottom: 0%;

    overflow: hidden;
    
    background-color: rgba(255,255,255,0.2);

    /*add a border at the top */
    border-top: 1px solid rgba(0,0,0,0.6);

    display: flex;

    /* justify-content: center; */
    flex-direction: column; 

    /*center children horizontally*/
    align-items: center;

    /*set spacing between columns to 20px*/
    grid-row-gap: 10px;

    z-index: 9;
    transition: opacity 1s;
    backdrop-filter: blur(10px);
    opacity: 1.0;
}

.Mint-pricing {
    width: 95%;
    position: relative;
    /*add a white glow to the text*/
    text-shadow: #ffffff 0px 0px 5px, #ffffff 0px 0px 5px, #ffffff 0px 0px 10px;
    display: grid;

    /* make first column 2x wider than second */

    justify-items: center;

    /* center children vertically */
    align-items: center;

    /* center children horizontally */
    justify-items: center;

    /*add spacing between the rows*/
    grid-row-gap: 20px;
}

.Mint-quantity-discount {
    width: 90%;
    text-align: center;
    white-space: nowrap;    
}

.Mint-price-key {
    /* two column grid */
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    width: 100%;
}

.Mint-prices {
    border-right: 1px solid rgb(85, 85, 85);
    text-align: right;
    padding: 5px;
}

.Mint-price {
    padding: 2px;
}

.Mint-price-conditions {
    text-align: left;
    padding: 5px;
}

.Mint-price-condition {
    padding: 2px;
}

.Info-img {
    position: relative;
    width: 80%;
}

.Info-text {
    width: 80%;
    position: relative;
    line-height: 1.5;
}

.Credit-line {
    border-top: 1px solid rgb(85, 85, 85, 0.2);
    padding-top: 20px;
    color:rgb(71, 71, 71)
}

.Creation-footer {
    position: relative;
    padding-bottom: 30vh;
}

.Info-popup-container {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0,0,0,0.6);
    z-index: 9;
    transition: opacity 1s;
    backdrop-filter: blur(5px);
    transform: translateZ(0);
    opacity: 0.0;
}

.Info-popup-container.visible {
    opacity: 1.0;
}

.Click-to-start {
    text-shadow: 0px 0px 10px #ffffff, 0px 0px 20px #ffffff;
    font-size: large;
}

.Navigation-tips {
    text-align: left;
    padding: 15px;
}

.Info-popup {
    left: 33.3vw;
    width: auto;
    border: 1px solid black;
    border-radius: 10px;
    padding: 20px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    box-shadow: 0px 0px 30px #ffffff;
    background-color: rgba(255,255,255,0.2);
    animation: glow-popup 3s infinite alternate;
    text-shadow: 0px 0px 10px #ffffff;
    text-align: center;

    -webkit-touch-callout: none; 
    -webkit-user-select: none;
     -khtml-user-select: none; 
       -moz-user-select: none;
        -ms-user-select: none; 
            user-select: none; 
}

.Loading {
    position: absolute;
    /* animation: color-anim 3s infinite; */
    top: 50%;
    left: 33.3vw;
    display: grid;
    /* center grid chlidren horizontally */
    justify-items: center;
    /* transform: translate(-50%, -50%); */
    transform: translate(-50%, -50%);
    font-family: 'Duke';
    font-size: 2em;

    -webkit-touch-callout: none; 
    -webkit-user-select: none;
     -khtml-user-select: none; 
       -moz-user-select: none;
        -ms-user-select: none; 
            user-select: none; 
}

.Timeline-container {
    width: 62%;
    height: 10%;
    position: absolute;
    bottom: 0px;
    z-index: 1;
    left: 2%;
    /* transform: translateX(-50%); */

    display: flex;
    flex-direction: row;

    justify-content: space-between;
    align-items: center;
    justify-items: center;
}

.Timeline-date {
    width: 10%;
    position: relative;
    font-size: medium;
    line-height: 1.2;
    bottom: 3px;
    z-index: 500;
    text-align: center;
    font-family: Duke;
    text-shadow: 0px 0px 20px #ffffff, 0px 0px 20px #ffffff, 0px 0px 20px #ffffff;
    font-size: 15px;
    -webkit-touch-callout: none; 
    -webkit-user-select: none;
     -khtml-user-select: none; 
       -moz-user-select: none;
        -ms-user-select: none; 
            user-select: none; 
}

.Timeline-line {
    width: 80%;
    position: relative;
    /* left: 50%;
    transform: translateX(-50%); */
    /* top: 50%; */
    height: 5px;
    background: black;  
    box-shadow: 0px 0px 20px #ffffff, 0px 0px 20px #ffffff;
}

.Timeline-line-thick {
    width: 100%;
    position: absolute;
    right: 0%;
    transform: translate(0%, -50%); 
    top: 50%; 
    z-index: 10;
    height: 5px;
    background: #c0c0c0;  
}


.Timeline-playhead {
    position: absolute;
    box-shadow: 0px 0px 20px #000, 0px 0px 20px #000;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #c0c0c0;
    top: 50%;
    z-index: 500;
    transform: translate(-50%, -50%);

    left: 0%;
}

.Timeline-point {
    position: absolute;
    background: black;
    border-radius: 0%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation-timing-function: ease-out;
}

/* mobile */
@media only screen and (max-width: 1000px) {

    .Audio-player {
        font-size: 10px;
        width: 125px;
        padding: 7px;
    }

    .Audio-player img {
        width: 20px;
    }

    @media (orientation: landscape) {
        .Creation-header {
            font-size: 0.8em;
        }    
    }

    @media (orientation: portrait) {
        .Creation-header {
            font-size: 1.2em;
        }    
    }

    .Mint-button-grid {
        /* set to 2 row grid */
        display: grid;
        grid-template-columns: 1fr 1fr;
        /* center items horizontally */
        justify-items: center;
    }

    .Loading {
        /* set grid direction to column */
        grid-auto-flow: row;
    }

    .Creation-logo-loading {
        width: 100%;
    }

    .Connect-button {
        font-size: 13px;
    }

    .Giveaway-button {
        font-size: 13px;
        width: 20vw;
    }

    .Address-pill {
        font-size: 13px;
    }

    .Mint-button {
        /* font-size: 10px; */
        font-size: 2.5vw;
        margin: 5px;
    }

    .Pan-arrows {
        left: 15vw;

        width: 10vh;
        height: 10vh;
    }

    .Split-separator {
        width: 3vw;
    }

    .OneOfOneOneOne-info {
        padding-top: 16vh;
        gap: 15px;
    }

    .Info-text {
        font-size: 10px;
    }

    .Creation-links {
        font-size: 10px;
    }

    .Mint-pricing {
        font-size: 2.0vw;
        line-height: 1.2;
    }

    .Timeline-container {
        width: 62%;
    }

    .Timeline-date {
        width: 20%;
        font-size: 80%;
    }

    .Timeline-line {
        width: 70%;
    }

    /* add an animation to scale width and height from 3px to 5px over 1 second */
    @-webkit-keyframes pulsePoint {
        0%  { 
            width: 2px; 
            height: 3px;
        }
        100% { 
            width: 2px;
            height: 25px;
        }
    }
    
    @keyframes pulsePoint {
        0%  { 
            width: 2px; 
            height: 3px;
        }
        100% { 
            width: 2px;
            height: 25px;
        }
    }    

    .Timeline-point {
        width: 2px;
        height: 3px;  
    }
}

/* desktop */
@media only screen and (min-width: 1000px) {
    .Audio-player {
        font-size: 12px;
        width: 150px;
        padding: 7px;
    }

    .Audio-player img {
        width: 25px;
    }

    @media (orientation: landscape) {
        .Creation-header {
            font-size: 1.4em;
        }    
    }

    @media (orientation: portrait) {
        .Creation-header {
            font-size: 2.0em;
        }    
    }


    .Loading {
        grid-auto-flow: column;
    }

    .Creation-logo-loading {
        width: 100%;
    }    

    .Connect-button {
        font-size: 18px;
    }

    .Address-pill {
        font-size: 18px;
    }

    .Mint-pricing {
        grid-template-columns: 2fr 1fr;
        width: 70%;
        line-height: 1.5;
        font-size: 15px;
    }

    .Mint-button {
        font-size: 16px;
        margin: 5px;
    }

    .Pan-arrows {
        left: 10vw;

        width: 8vh;
        height: 8vh;
    }

    .Split-separator {
        width: 1vw;
    }

    .OneOfOneOneOne-info {
        padding-top: 16vh;
    }

    .Info-img {
        width: 50%;
    }

    .Info-text {
        font-size: 15px;
    }

    .Creation-links {
        font-size: 15px;
    }

    .Mint-pricing {
        font-size: 15px;
    }

    .Timeline-date {
        width: 5%;
        font-size: 80%;
    }

    .Timeline-line {
        width: 90%;
    }

    @-webkit-keyframes pulsePoint {
        0%  { 
            width: 4px; 
            height: 5px;
        }
        100% { 
            width: 2px;
            height: 50px;
        }
    }
    
    @keyframes pulsePoint {
        0%  { 
            width: 4px; 
            height: 5px;
        }
        100% { 
            width: 2px;
            height: 50px;
        }
    }    

    .Timeline-point {
        width: 4px;
        height: 5px;    
    }
}
