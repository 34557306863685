/*
ContractInfo
Etherscan-link
Opensea-link
*/


/* Add top level div Nome in front of each class because React doesn't scope CSS based on components */

:root {
    --hide-mobile-overlay-height: calc(40vh);
    --show-mobile-overlay-height: calc(100vh - 20vh);
    --hide-desktop-overlay-top-wanderlust: calc(100vh - 370px);
    --show-desktop-overlay-top: 25vh;
}

.Nome .showOverlayDesktop {
    top: var(--show-desktop-overlay-top) !important;
}    

.Nome .showOverlayDesktopHeight {
    height: calc( 100vh - var(--show-desktop-overlay-top) ) !important;
}

.Nome .showOverlayMobile {
    top: calc(100vh - var(--show-mobile-overlay-height)) !important;
}

.Nome .showOverlayMobileHeight {
    height: calc(var(--show-mobile-overlay-height)) !important;
}

.Nome .hideOverlayDesktop {
    top: var(--hide-desktop-overlay-top-wanderlust) !important;
}

.Nome .hideOverlayDesktopHeight {
    height: calc( 100vh - var(--hide-desktop-overlay-top-wanderlust)) !important;
}

.Nome .hideOverlayMobile {
    top: calc(100vh - var(--hide-mobile-overlay-height)) !important;
}

.Nome .hideOverlayMobileHeight {
    height: calc(var(--hide-mobile-overlay-height)) !important;
}

@keyframes rotateSubtle {
    0%, 10% { transform: rotate(0deg); }
    20% { transform: rotate(-5deg); }
    30% { transform: rotate(5deg); }
    40%, 100% { transform: rotate(0deg); }
}
  
.Nome .degen-bg-splash {
    z-index: -1;
    background-image: url("../images/splash_bg.jpg") !important;
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0px;
    top: 0px;
    padding: 1px;
    background-size: cover;
    background-position: 50% 13%;
    background-repeat: no-repeat;
}

.Nome .EnterGallery {
    width: max-content;
    right: 4vw;
    top: 20vh;

    color: black;

    font-family: Union;
  
    z-index: 10000;
    background: none;
    position: fixed;
    padding: 10px;

    font-size: 0.8em;

    line-height: 1.3;

    text-decoration: none;
  
    text-shadow: 0px 0px 5px #ffffff, 0px 0px 10px #ffffff, 0px 0px 10px #ffffff;

    filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.7));

    animation: rotateSubtle 1s linear infinite;
}

.Nome .EnterGalleryMobile {
    width: max-content;
    right: 0vw;
    top: 1vh;

    color: black;

    font-family: Union;
  
    z-index: 10000;
    background: none;
    position: fixed;
    padding: 10px;

    font-size: 0.8em;

    line-height: 1.3;

    text-decoration: none;
  
    text-shadow: 0px 0px 5px #ffffff, 0px 0px 10px #ffffff, 0px 0px 10px #ffffff;

    filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.7));

    animation: rotateSubtle 1s linear infinite;
}

.Nome .Tutorial { 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 50%;
    z-index: 100000;
    opacity: 1;
    cursor: none;
    /* pointer-events: none; */
    
    background-image: url("../images/map.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    /* add strong drop shadow */
    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.7));

    /* disable scrolling */
    overflow: hidden;   
}

.Nome .TutorialMobile { 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%);
    width: 80%;
    height: 80%;
    z-index: 100;
    opacity: 1;
    cursor: none;
    /* pointer-events: none; */
    
    background-image: url("../images/map.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    /* add strong drop shadow */
    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.7));

    /* disable scrolling */
    overflow: hidden;   
}


.Nome .EnterGalleryImage {
    width: 300px;
    object-fit: cover;
    border-radius: 5px;
}

.Nome .EnterGalleryImageMobile {
    width: 150px;
    object-fit: cover;
    border-radius: 5px;
}


.Nome .tabs {
    position: fixed;
    top: 25vh;
    left: 5vw;
    width: 60vw;
    min-height: 60px;
    display: flex;
    z-index: 1000;
}

.Nome .tabsMobile {
    position: fixed;
    top: 25vh;
    /* center horizontally */
    left: 50%;
    transform: translate(-50%, 0);
    width: auto;
    min-height: 60px;
    display: flex;
    z-index: 1000;
}

.Nome .tab {
    padding: 15px 30px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    backdrop-filter: blur(5px);
    background-color: #f2f2f25d;
    border: 0.5px solid rgb(77, 77, 77);

    font-family: Union;
    /* font-weight: bold; */
    font-size: 15px;
}

.Nome .tab:hover {
    background-color: #00ffe1;
}

.Nome .tab.active {
    background-color: #05e2fb;
}

.Nome .tabsMobile .tab:first-child {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

.Nome .tabsMobile .tab:last-child {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.Nome .tabs .tab:first-child {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

.Nome .tabs .tab:last-child {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.Nome .tab-content {
    margin-top: 30vh;
    padding: 20px;
    width: 60vw;

    padding-bottom: 10vh;

    /* center on page */
    margin-left: auto;
    margin-right: auto;

    display: flex;
    flex-direction: column;

    align-items: center;
}

.Nome .tab-content-mobile {
    margin-top: 35vh;
    padding: 20px;
    display: flex;
    flex-direction: column;

    align-items: center;
}

.Nome .tab-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 40px;
}

.Nome .GachaGridContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns with equal width */
    grid-template-rows: repeat(2, auto);   /* 2 rows with auto height */
    gap: 10px; /* Space between grid cells, can be adjusted */
    width: 100%; /* The grid fills the width of its containing element */
}

.Nome .GachaGridContainer img {
    width: 100%;
    object-fit: contain;
}

.Nome .DegenItem {
    display: flex;
    flex-direction: column;;
    align-items: center;
    justify-content: center;
    row-gap: 10px;
}


.Nome .OverlayDesktop {
    z-index: 9;
    width: 15vw;
    min-width: 300px;
    right: 0;
    top: var(--hide-desktop-overlay-top-wanderlust);
    height: calc( 100vh - var(--hide-desktop-overlay-top-wanderlust));

    position: absolute;

    cursor: none;

    display: flex;
    flex-direction: column;

    transition: all 0.5s ease-in-out;

    row-gap: 40px;

    box-shadow: 0px 0px 30px rgb(0 0 0 / 30%);

    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
}

.Nome .mintBox { 
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    height: 60px;
}

/* .Nome .oeMintBox {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
} */

.Nome .mintButton {
    width: auto;
    /* make sure button fits text vertically */
    height: auto;
    font-family: Duke;
    font-weight: bold;
    font-size: 15px;
    color: black;
}

.Nome .mintSuite {
    width: auto;
    font-family: Duke;
    font-weight: bold;
    font-size: 15px;
    color: black;

    position: fixed;

    animation: glow 8s infinite !important;  

    /*center vertically and horizontally on screen */
    bottom: 5%;
    left: 50%;
    transform: translate(-50%, -50%);

    padding: 5px;

    z-index: 100;
}

.Nome .mintSetContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    justify-content: center;
}

.Nome .mintSetButton {
    width: 100%;
    height: auto;
}

.Nome .counterCenterContainer {
    display: flex;
	border: 1px solid #404040;
	background: #dfdfdf;
	border-radius: 10px;
	width: 60px;
	padding: 5px;
	justify-content: space-between;
	align-items: center;
	align-content: center;
    font-size: 15px;
}

.Nome .counterNumberCol h1 {
    margin: 0;
    padding: 0;
    user-select: none;
    width: 100%;
    background: none;
    border: 0px;
    text-align: center;
}
  
.Nome .counterButtonCol {
    width: 37.5%;
}
  
.Nome .counterNumberCol {
    width: 25%;
    font-size: 12px;
}
  
.Nome .counterButtonCol > button {
    margin: 0;
    padding: 0;
    border: none;

    width: 100%;
    background: none;
    border: 0px;
    text-align: center;
    font-family: sans-serif;
    font-size: 30px;
    color: #686868;
    user-select: none;
    outline: none;
}  

.Nome .OverlayMobile {
    z-index: 9;
    width: 100vw;
    top: calc(100vh - var(--hide-mobile-overlay-height));
    height: var(--hide-mobile-overlay-height);

    position: absolute;

    display: flex;
    flex-direction: column;

    transition: all 0.5s ease-in-out;

    row-gap: 40px;

    box-shadow: 0px 0px 30px rgb(0 0 0 / 30%);

    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
}

.Nome .OverlayInnerDesktop {
    -webkit-mask-image: linear-gradient(to bottom, black 75%, transparent 95%);
    mask-image: linear-gradient(to bottom, black 75%, transparent 95%);  

    position: absolute;
    width: 100%;
    height: 100%;
    overflow: scroll;

    cursor: none;
}

.Nome .OverlayInnerMobile {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: scroll;
}

.Nome .OverlayCurrentBidInfo {
    font-family: Duke;
    font-weight: bold;
    color: white;
    max-width: 50%;
    text-shadow: 0 0 5px black,  0 0 5px black,  0 0 5px black;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.Nome .BidBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
}

.Nome .HighestBidInfo {
    font-family: Union;
    display: flex;
    /* justify-content: space-between; */
    flex-direction: row;
    font-weight: normal;
    font-size: 14px;
    color: white;
    text-shadow: none;
    text-shadow: 0 0 3px black, 0 0 3px black;
}

.Nome .BidInput {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    height: 30px;
}

.Nome .BidInputBox {
    width: 50px;
    font-size: 14px;
    text-align: center;
}

.Nome .BidButton {
    width: 50px;
    font-family: Duke;
    font-weight: bold;
    font-size: 12px;
    color: black;
}

.Nome .BidHistoryList {
    display: flex;
    flex-direction: column-reverse;    
}

.Nome .AuctionEndTimer {
    font-family: Union;
    font-weight: normal;
    padding-top: 10px;
    color: white;
    text-shadow: 0 0 5px black,  0 0 5px black,  0 0 5px black;
    font-size: 12px;    
}

.Nome .OverlayPane {
    position: absolute;
    overflow: scroll;

    padding-top: 125px;

    padding-bottom: 100px;

    display: flex;
    row-gap: 20px;
    flex-direction: column;

    width: 100%;
}

.Nome .AuctionTimingNote {
    padding-left: 10px;
    padding-right: 10px;
    color: white;
    text-shadow: 0 0 5px black,  0 0 5px black,  0 0 5px black;
    font-size: 12px;    
}

.Nome .OverlayPaneItem {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.Nome .OverlayPaneItem a {
    color: pink;
}

.Nome .OverlayPaneItemDegen {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    backdrop-filter: blur(5px);
    background-color: #f2f2f25d;
    border: 0.5px solid rgb(77, 77, 77);
    border-radius: 5px;
}

.Nome .OverlayPaneItemDegen a {
    color: pink;
}


.Nome .OverlayPaneArtHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    align-items: center;

    width: 100%;

    opacity: 1.0;

    grid-column-gap: 5px;
}
    
.Nome .OverlayPaneInfoDesktop {
    color: white;
    text-shadow: 0 0 5px black, 0 0 5px black, 0 0 5px black;
    font-size: 16px;
    opacity: 1.0;
}

.Nome .OverlayPaneInfoDesktop a {
    color: rgb(248, 160, 226);
    text-shadow: 0 0 2px black, 0 0 2px black;
    font-size: 16px;
}

.Nome .OverlayPaneInfoMobile {
    color: white;
    text-shadow: 0 0 5px black, 0 0 5px black, 0 0 5px black;
    font-size: 16px;
}

.Nome .OverlayPaneInfoMobile a {
    color: rgb(248, 160, 226);
    text-shadow: 0 0 2px black, 0 0 2px black;
    font-size: 16px;
}

.Nome .ArtThumbnailContainer {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;

    row-gap: 5px;
}

.Nome .ArtThumbnailImg {
    width: auto;
    height: auto;

    max-height: 50%;
    max-width: 95%;
    object-fit: contain;
}

.Nome .ArtThumbnailImg img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
}

.Nome .ArtThumbnailImgDegen {
    width: auto;
    height: auto;

    max-height: 50%;
    width: 100%;
    object-fit: contain;
}

.Nome .ArtThumbnailImgDegen img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
}

.Nome .PriceGrid {
    display: flex;
    flex-direction: column;
    /* align-items: center;
    justify-content: center; */
    row-gap: 12px;
    /* grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-column-gap: 20px;
    grid-row-gap: 20px; */
    padding: 10px;
    width: 100%;
    height: auto;
}

.Nome .PriceGridDegen {
    display: flex;
    flex-direction: column;
    /* align-items: center;
    justify-content: center; */
    row-gap: 12px;
    /* grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-column-gap: 20px;
    grid-row-gap: 20px; */
    padding: 10px;
    width: auto;
    height: auto;
    backdrop-filter: blur(5px);
    background-color: #f2f2f25d;
    border: 0.5px solid rgb(77, 77, 77);
    border-radius: 5px;
}


.Nome .DiscountGrid {
    border: 1px solid rgb(175, 175, 175);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 10px;
    row-gap: 10px;
    /* grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-column-gap: 20px;
    grid-row-gap: 20px; */
    width: 80%;
    height: auto;
}

.Nome .DiscountGridInner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    width: 100%;
    height: auto;
}

.Nome .ArtHeaderTitleName {
    font-family: Duke;
    font-weight: bold;
    font-size: 18px;
    width: 100%;
    height: auto;
    color: white;
    text-shadow: 0 0 5px black,  0 0 5px black,  0 0 5px black;
    display: flex; 
    flex-direction: column;
    gap: 10px;
}

.Nome .WorkType {
    font-family: Union;
    font-weight: normal;
    font-size: 12px;
    color: white;
    background: #30c9fc;
    text-shadow: 0 0 2px black,  0 0 2px black,  0 0 2px black;
    /* text-shadow: none; */
    border: 1px solid black;
    padding: 5px;
    width:fit-content;
    border-radius: 5px;
}

.Nome .Address-pill {
    color: black;
    z-index: 2;
    border: 1px solid #3f3f3f;
    backdrop-filter: blur(5px);
    background-color: rgba(255,255,255,0.2);
    box-shadow: 0px 0px 30px #55ffe871, 0px 0px 15px #55ffe871;
    position: fixed;
    border-radius: 5px;
    right: 3vw;
    top: 18vh;
    padding: 10px;
    border-radius: 5px;
    width: max-content;
}

.Nome .Connect-button {
    width: max-content;
    right: 3vw;

    color: black;

    font-family: Union;

    cursor: none;
  
    z-index: 1000;
    border: 1px solid #3f3f3f;
    backdrop-filter: blur(5px);
    background-color: rgba(255,255,255,0.2);
    position: fixed;
    border-radius: 5px;
    padding: 10px;
    border-radius: 5px;
  
    text-shadow: 0px 0px 5px #ffffff, 0px 0px 10px #ffffff, 0px 0px 10px #ffffff;
  
    animation: glow 8s infinite;  
}

.Nome .Connect-button-mobile {
    top: 13vh;
}

.Nome .Connect-button-desktop {
    top: 18vh;
}

.Nome .Nome-container {
    width: 100vw;
    height: 100vh;
    position: absolute;

    /* prevent scrolling */
    overflow: hidden !important;
    /* cursor: none; */

    /* opacity: 0.0; */

    touch-action: none;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Nome .WorkNavigatorDesktop {
    z-index: 10;

    width: 15vw;
    min-width: 300px;
    right: 0;
    top: var(--hide-desktop-overlay-top-wanderlust);

    cursor: none;

    position: absolute;

    display: flex;
    flex-direction: column;

    transition: all 0.5s ease-in-out;

    padding-top: 5px;
    padding-bottom: 5px;

    backdrop-filter: blur(5px);

    box-shadow: 0px 0px 30px rgb(0 0 0 / 30%);

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Nome .WorkNavigatorMobile {
    z-index: 10;

    width: 100vw;
    top: calc(100vh - var(--hide-mobile-overlay-height));

    position: absolute;

    touch-action: none;

    cursor: none;

    display: flex;

    flex-direction: column;

    transition: all 0.5s ease-in-out;

    padding-top: 5px;
    padding-bottom: 5px;

    backdrop-filter: blur(5px);

    box-shadow: 0px 0px 30px rgb(0 0 0 / 30%);

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Nome .AvatarController {
    z-index: 11;

    width: 100%;
    right: 0;

    cursor: none;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    transition: all 0.5s ease-in-out;

    padding-top: 15px;
    padding-bottom: 15px;
    
    -webkit-user-select: none; 
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Nome .ContractInfo {
    font-size: 16px;
    justify-content: end;
    color: white;
    text-shadow: 0 0 5px black,  0 0 5px black,  0 0 5px black;
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    font-weight: normal;
    width: 100%;
}

.Nome .Etherscan-link {
    display: flex;
    align-items: center;
}

.Nome .Etherscan-link img {
    width: 25px;
    height: 25px;
    filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.7));
}

.Nome .Opensea-link {
    display: flex;
    align-items: center;
}

.Nome .Opensea-link img {
    width: 25px;
    height: 25px;
    filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.7));
}

.Nome .NavigatorButtonRow {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.Nome .WorkNavigatorButtonMute {
margin-top: 6px;
    width: 35px;
    height: 35px;
    position: relative;
    background-size: contain;
}

.Nome .SoundOff {
    background: url('../images/soundoff.png') no-repeat center;
    background-size: contain;
}

.Nome .SoundOn {
    background: url('../images/soundon.png') no-repeat center;
    background-size: contain;
}

.Nome .WorkNavigatorProgressBar {
    background-color:rgba(0, 255, 225, 0.2);
    /* background: hsl(0%,100%,70%); */
    width: 0%;
    height: 100%;
    position: absolute;

    animation: hueRotateAnim 8s infinite;

    /* center on parent div, which is absolute */
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
}

.Nome .WorkNavigatorButtonPlay {
    margin-top: 6px;
    width: 40px;
    height: 40px;
    position: relative;
}

.Nome .PlayTour {
    background: url('../images/tour_play.png') no-repeat center;
    background-size: contain;
}

.Nome .PauseTour {
    background: url('../images/tour_pause.png') no-repeat center;
    background-size: contain;
}

.Nome .WorkNavigatorButtonPrev {
    /* margin-top: 5px; */
    width: 50px;
    height: 50px;
    position: relative;
    background: url('../images/tour_L.png') no-repeat center;
    background-size: contain;
    background-repeat: no-repeat;
}

.Nome .WorkNavigatorButtonNext {
    /* margin-top: 5px; */
    width: 50px;
    height: 50px;
    position: relative;
    background: url('../images/tour_R.png') no-repeat center;
    background-size: contain;
    background-repeat: no-repeat;
}

.Nome .WorkNavigatorButtonArrow {
    margin-top: 6px;
    width: 35px;
    height: 35px;
    position: relative;
    background-size: contain;
}

.Nome .Arrow {
    background: url('../images/arrow.png') no-repeat center;
    background-size: contain;
}

.Nome .Cross {
    background: url('../images/cross.png') no-repeat center;
    background-size: contain;
}

.Nome .MessageBar {
    display: flex;
    flex-direction: row;
    flex-basis: 65%;
    justify-content: space-evenly;
    align-items: center;
    height: 28px;

    /* set spacing between elements to 20px */
    gap: 8px;
}

.Nome .MessageBox {
    border-radius: 5px;
    border: none;
    height: 100%;
    flex-basis: 90%;
    font-family: Duke;
}

.Nome .MessageBox::placeholder {
    color: #540051;
}

.Nome .SendMessageButton {
    height: 28px;
    width: 28px;
    aspect-ratio: 1/1;
    background-image: url("../images/up_arrow_white.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    border: 1px solid white;
    background-color: transparent;
}

.Nome .hueAndPulse {
    animation: hueRotateAnim 4s infinite, pulseAnim 0.3s infinite ease-in-out, fadeInAnim 0.5s; 
}

.Nome .hueAnim {
    animation: hueRotateAnim 4s infinite, pulseAnim 3s infinite ease-in-out, fadeInAnim 0.5s;
}

.Nome .Nome-joystick {
    position: absolute;
    top: 45vh;

    left: 8vw;
    z-index: 500;
    
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Nome .Nome-jump-button {
    position: absolute;
    top: 45vh;

    right: 10vw;
    z-index: 500;
    width: 100px;
    height: 100px;

    border: none;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Nome .Jump {
    background: url('../images/jump.png') no-repeat center;
    background-size: contain;
}

.Nome .Crosshair {
    position: absolute;
    z-index: 1000000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    mix-blend-mode: color-burn;
    background-image: url('../images/crosshair_stroke.png');
    background-size: contain;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Nome .Crosshair-mobile {
    opacity: 0.0;
    width: 25px;
    height: 25px;
    mix-blend-mode: screen;
}

.Nome .Crosshair-desktop {
    opacity: 1.0;
    width: 35px;
    height: 35px;
}
