@font-face {
    font-family: 'Duke';
    src: local('Duke'), url(../fonts/DUKECONN.TTF) format('truetype');
}

@keyframes glow {
    0% { box-shadow: 0px 0px 20px rgba(240, 24, 24, 0.7), 0px 0px 10px rgba(240, 24, 24, 0.5); }
    20% { box-shadow: 0px 0px 20px rgba(255, 0, 200, 0.7), 0px 0px 10px rgba(255, 0, 200, 0.5); }
    40% { box-shadow: 0px 0px 20px rgba(240, 24, 24, 0.7), 0px 0px 10px rgba(240, 24, 24, 0.5); }
    
    60% { box-shadow: 0px 0px 20px rgba(15, 231, 231, 0.7), 0px 0px 10px rgba(15, 231, 231, 0.5); }
    80% { box-shadow: 0px 0px 20px rgba(0, 255, 55, 0.7), 0px 0px 10px rgba(0, 255, 55, 0.5); }
    100% { box-shadow: 0px 0px 20px rgba(240, 24, 24, 0.7), 0px 0px 10px rgba(240, 24, 24, 0.5); }
}  

@keyframes halo-anim {
    0% { filter: drop-shadow(0px 0px 10px rgba(240, 24, 24, 0.8)) }
    33% { filter: drop-shadow(0px 0px 10px rgba(255, 85, 0, 0.8))  }
    66% { filter: drop-shadow(0px 0px 10px rgba(255, 0, 200, 0.8)) }
    100% { filter: drop-shadow(0px 0px 10px rgba(240, 24, 24, 0.8)) }
}

@keyframes rotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.tantrumMintLink {
    border: 1px solid rgb(20, 20, 20);
    border-radius: 5px;
    padding: 2.5px;
    text-align: center;
    width: max-content;
    /* backdrop-filter: blur(5px); */
    background-color: rgba(255,255,255,0.2);
    animation: glow 8s infinite;  
    white-space: nowrap;

    text-decoration: none !important;

    font-size: 15px;
    text-shadow: 0px 0px 5px #000, 0px 0px 10px #000, 0px 0px 10px #000;
    color: rgb(255, 255, 255);
}

.tantrumMintLink a {
    font-family: Duke;

    backdrop-filter: blur(5px);

    text-decoration: none !important;
}

.tantrumLoading {
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    background: none;
    font-family: Duke;
    top: 0px;
    font-size: medium;
    font-weight: 100;
    z-index: 0;
    justify-content: center;
    align-items: center;

    /* touch-action: none; */

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;    
}


.gigiContainer {
    width: 100vw;
    height: 100vh;
    position: fixed;

    top: 0px;

    /* prevent scrolling */
    overflow: hidden !important;

    opacity: 1.0;

    /* touch-action: none; */

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.tantrumVideoContainer {
    width: 100%;
    display: flex;
    gap: 7px;

    flex-direction: column;

    justify-content: center;
    align-items: center;
}

.tantrumSponsoredVideo {
    max-width: 250px;
}

.avatarToken {
    width: 60px;
    height: 60px;
    /* add a heavy dropshadow */
    /* filter: drop-shadow(0px 0px 15px rgb(175, 45, 255)); */
}

.avatarToken img {
    max-width: 100%;
}

.chatContainer {
    z-index: 10;
    position: relative;

    /* mix-blend-mode: hard-light; */
}

.chat-input {
    display: flex; 
    justify-content: space-between;  
    align-items: center;  
    position: fixed;
    bottom: 0;
    width: 100%;
    background: none;
    padding: 10px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    backdrop-filter: blur(5px);
}

.chat-input input {
    width: 100%; 
    height: 35px;
    padding: 5px;
    border: 1px solid #ccc;

    font-family: Duke;

    font-size: 15px;
    border-radius: 5px;
    box-sizing: border-box;
}

.chat-input button {
    height: 35px;  
    padding: 5px;
    border: none;
    border-radius: 5px;
    background: #0084ff;

    font-family: Duke;

    color: #fff;
    box-sizing: border-box;
    margin-left: 10px;  /* Add some space between input and button */
}

.TantrumSpinner {
    position: fixed;
    top: 25px;
    right: 25px;
    width: 75px;
    height: 75px;
    animation: rotate 8s linear infinite, halo-anim 8s infinite;
    z-index: 100;
    /* filter: drop-shadow(0px 0px 15px rgb(175, 45, 255)); */
}

.TantrumSpinner img {
    width: 100%;
    height: 100%;
}

.message-list {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 60px;
}


.message {
    display: flex;
    align-items: center;
    border-radius: 20px;
    padding: 10px;
    margin-bottom: 10px;
    max-width: 100%; /* you can adjust as needed */

    gap: 5px;
}

.messageBubble {
    margin: 0;
    padding: 10px;
    border-radius: 10px;
    background: #f0f0f030; /* you can adjust as needed */
    /* backdrop-filter: blur(5px); */

    text-shadow: 0px 0px 5px #000, 0px 0px 10px #000, 0px 0px 10px #000;
    color: rgb(255, 255, 255);

    line-height: 1.6;

    font-family: Duke;
    font-size: 15px;

    border: 0.5px solid #000000;
}

.message-Gigi {
    display: flex;
    align-items: center;
    border-radius: 20px;
    padding: 10px;
    margin-bottom: 10px;
    max-width: 100%; /* you can adjust as needed */

    gap: 5px;
}

.messageGigiBubble {
    margin: 0;
    padding: 10px;
    border-radius: 10px;
    background: #ff6ddf30; /* you can adjust as needed */
    /* backdrop-filter: blur(5px); */

    text-shadow: 0px 0px 5px #000, 0px 0px 10px #000, 0px 0px 10px #000;
    color: rgb(213, 210, 251);

    line-height: 1.6;

    font-family: Duke;
    font-size: 15px;

    border: 0.5px solid #000000;
}


.avatar {
    margin-right: 10px;
    font-size: 20px;
}
